<script>
  import { _, locale } from "svelte-i18n";
  import { push, location, link } from "svelte-spa-router";
  import ValidationUser from "../../authentication-service";
  import PropertyFeatures from "../../property-features-service";
  import ModalMobile from "../modal/mobile/component.svelte";
  import ModalDesktop from "../modal/desktop/component.svelte";
  import Reloading from "./reloading-component.svelte";
  import RoutesServices from "../../management-routes-service";

  export let closeMenuSidebar;
  export let propertyId;
  export let logoUrl;
  export let listLanguagesEnabled;
  export let requestsPending = 0;
  export let features;
  export let backgroundColorView;
  export let colorLetter;
  export let managementColors;
  export let requestsList;
  export let refresh;
  export let handleLanguage;
  let auth = JSON.parse(localStorage.getItem("auth"))
    ? JSON.parse(localStorage.getItem("auth"))
    : false;

  let showModal = false;
  let body = document.body;
  let languages = [];
  let languaguesList = [];
  let dropLanguage = false;
  let cliclInter = false;
  let language;
  let languageActual;
  let propEmpty = "";
  let screenPxs = window.innerWidth;
  let mobileScreen = true;

  const init = () => {
    languageValidation();
    resizeView();
    setFontFamily(null);
  };

  const languageValidation = () => {
    listLanguagesEnabled.map((l) => {
      if (l == "EN") {
        languaguesList.push({
          name: "ENG",
          codeName: "en",
        });
      } else if (l == "ES") {
        languaguesList.push({
          name: "ESP",
          codeName: "es",
        });
      }
    });

    localStorage.getItem("language")
      ? (language = localStorage.getItem("language"))
      : (language = window.navigator.language.split("-")[0]);

    languaguesList.find((lang) => lang.codeName == language)
      ? (languageActual = languaguesList.find(
          (lang) => lang.codeName == language
        ).codeName)
      : (languageActual = language);

    if (languaguesList.length == 1) {
      languages = [];
    } else {
      languages = languaguesList.filter((lang) => lang.codeName != language);
    }
  };

  let resizeView = () => {
    if (screenPxs >= 600) mobileScreen = false;

    window.addEventListener("resize", () => {
      screenPxs = window.innerWidth;
      if (screenPxs >= 600) {
        mobileScreen = false;
      } else {
        mobileScreen = true;
      }
    });
  };

  const hoverLinks = (i) => {
    if (window.innerWidth >= 992) {
      const element = document.getElementById(`${i}`);
      element
        ? element.style.setProperty("color", managementColors.color)
        : null;
    }
  };

  const handleMouseOutLinks = (i) => {
    if (window.innerWidth >= 992) {
      const element = document.getElementById(`${i}`);
      element ? element.style.setProperty("color", colorLetter) : null;
    }
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  init();

  window.addEventListener("click", function (e) {
    if (
      document.getElementById("drop") &&
      document.getElementById("drop").contains(e.target)
    ) {
    } else {
      dropLanguage = false;
    }
  });

  window.addEventListener("click", function (e) {
    if (
      document.getElementById("side") &&
      document.getElementById("side").contains(e.target)
    ) {
      setTimeout(function () {
        if (!cliclInter && document.getElementById("sidemenu"))
          closeMenuSidebar();
      }, 50);
    } else {
    }
  });

  window.addEventListener("click", function (e) {
    if (
      document.getElementById("sidemenu") &&
      document.getElementById("sidemenu").contains(e.target)
    ) {
      cliclInter = true;
    } else {
      cliclInter = false;
    }
  });

  let sesionAction = () => {
    if (auth) {
      showModal = true;
    } else {
      push(`/properties/${propertyId}/user-validation`);
      body.style.overflowY = "auto";
    }
  };

  let goFeaure = (feature) => {
    validationDataHeader(feature);
    if (feature.isDefault) {
      if (
        feature.codename == "room-service.list" ||
        feature.codename == "home" ||
        feature.codename == "myrequests" ||
        (feature.codename == "requests.create" &&
          feature.settings.loginRequired)
      ) {
        let routeLink = PropertyFeatures.getRouteFeature(
          feature.codename,
          propertyId,
          feature._id
        );
        validationSameRuteAndAuth(routeLink, feature);
      } else {
        push(feature.href[0].link);
      }
    } else {
      if (
        feature.codename == "other" ||
        feature.codename == "restaurant" ||
        feature.codename == "service"
      ) {
        if (feature.codename == "other" || feature.codename == "restaurant") {
          localStorage.setItem("amountRestaurants", JSON.stringify(1));
        } else {
          localStorage.setItem("amountServices", JSON.stringify(1));
        }
        push(`${feature.href[0].link}`);
      } else if (feature.codename == "promotion") {
        push(
          `/properties/${propertyId}/section/${feature.codename}/${feature.outletId}`
        );
      } else if (feature.codename == "myrequests") {
        push(`/properties/${propertyId}/my-experiences`);
      } else {
        push(`/properties/${propertyId}`);
      }
    }
    setTimeout(() => {
      handleLanguage(language);
    }, 100);

    closeMenuSidebar();
  };

  let validationDataHeader = (feature) => {
    switch (feature.codename) {
      case "home":
        var tile = {
          featureId: 1,
          nameFeature: "Home",
          codeName: "home",
          imgFeature: logoUrl,
          link: `/properties/${propertyId}`,
        };
        break;
      case "property.details":
        var tile = {
          featureId: feature._id,
          nameFeature: feature.caption,
          codeName: feature.codename,
          imgFeature: feature.imgUrl,
          link: `/properties/${propertyId}`,
        };
        break;
      case "requests.create":
        var tile = {
          featureId: 1,
          codeName: "requests.create",
          nameFeature: features.find((fet) => fet.codename == "requests.create")
            .caption,
          imgFeature: features.find((fet) => fet.codename == "requests.create")
            .imgUrl,
          link: `/properties/${propertyId}/create-requests`,
        };
        break;
      case "myrequests":
        var tile = {
          featureId: 1,
          nameFeature: "Home",
          codeName: "myrequests",
          imgFeature: logoUrl,
          link: `/properties/${propertyId}`,
        };
        break;
      case "restaurants.list":
        var tile = {
          featureId: feature._id,
          nameFeature: feature.caption,
          codeName: feature.codename,
          imgFeature: feature.imgUrl,
          link: `/properties/${propertyId}/restaurants`,
        };
        break;
      case "service.list":
        var tile = {
          featureId: feature._id,
          nameFeature: feature.caption,
          codeName: feature.codename,
          imgFeature: feature.imgUrl,
          link: `/properties/${propertyId}/services`,
        };
        break;
      case "promotions.list":
        var tile = {
          featureId: feature._id,
          nameFeature: feature.caption,
          codeName: feature.codename,
          imgFeature: feature.imgUrl,
          link: `/properties/${propertyId}/promotions`,
        };
        break;
      case "room-service.list":
        var tile = {
          featureId: feature._id,
          nameFeature: feature.caption,
          codeName: feature.codename,
          imgFeature: feature.imgUrl,
          link: `/properties/${propertyId}/room-services`,
        };
        break;
      default:
        var tile = {
          featureId: feature._id,
          nameFeature: feature.caption,
          codeName: feature.codename,
          imgFeature: feature.imgUrl,
          link: `/properties/${propertyId}/feature/${feature._id}/list-items`,
        };
    }
    localStorage.setItem("dataHeader", JSON.stringify(tile));
  };

  let validationTypeFile = (href, typeFile, validUrl) => {
    if (
      (typeFile == "link" && !validUrl) ||
      href.split("services.").length == 2
    ) {
      return "IframeItem";
    } else if (
      /\.(png)$/i.test(href) ||
      /\.(jpg)$/i.test(href) ||
      /\.(jpeg)$/i.test(href) ||
      /\.(gif)$/i.test(href) ||
      /\.(bmp)$/i.test(href) ||
      /\.(tif)$/i.test(href) ||
      /\.(tiff)$/i.test(href)
    ) {
      return "ImgItem";
    } else if (typeFile == "link" && validUrl) {
      return "OtherTab";
    } else {
      return "fileDownload";
    }
  };

  let goFile = (href, typeFile, validUrl, feature) => {
    let type = validationTypeFile(href, typeFile, validUrl);
    if (type == "IframeItem") {
      JSON.parse(localStorage.getItem("ImgItem"))
        ? localStorage.removeItem("ImgItem")
        : null;

      localStorage.setItem("IframeItem", JSON.stringify(href));
      href.split("properties").length == 1
        ? RoutesServices.setEntryPoint(false)
        : null;
      push(
        `/properties/${propertyId}/feature/${feature._id}/detail-item/${feature.codename}`
      );
    } else if (type == "ImgItem") {
      JSON.parse(localStorage.getItem("IframeItem"))
        ? localStorage.removeItem("IframeItem")
        : null;

      localStorage.setItem("ImgItem", JSON.stringify(href));
      push(
        `/properties/${propertyId}/feature/${feature._id}/detail-item/${feature.codename}`
      );
    } else if (type == "OtherTab") {
      window.open(href, "_blank").focus();
    } else {
      let pdfDefault = `https://docs.google.com/viewerng/viewer?url=${href}`;
      //handleFileDownloading(true);
      window.location.href = pdfDefault;
    }
  };

  let validationSameRuteAndAuth = (routeLink, feature) => {
    if ($location == routeLink) {
      closeMenuSidebar();
    } else {
      if (
        feature.codename == "room-service.list" ||
        feature.codename == "requests.create"
      ) {
        !feature.settings.loginRequired || auth
          ? push(routeLink)
          : push(
              `/properties/${propertyId}/user-validation?router=${routeLink}`
            );
      } else if (feature.codename == "service.list") {
        push(feature.href[0].link);
      } else {
        push(routeLink);
      }
    }
    body.style.overflowY = "auto";
  };

  let handleModal = () => {
    showModal = false;
  };

  let onLogOut = () => {
    ValidationUser.logOut(propertyId).then((response) => {
      refresh(true);
      push(`/properties/${propertyId}`);
      window.location.reload();
    });
  };

  let changeLanguage = (data) => {
    if (auth) {
      ValidationUser.updateLanguage(propertyId, {
        code: auth.code,
        language: data.codeName,
      });
    }
    language = data.codeName;
    languageActual = data.name;
    locale.set(data.codeName);
    languages = languaguesList.filter((lang) => lang.codeName != language);
    handleLanguage(language);
  };
</script>

<div
  id="side"
  class:mydrawer__base={true}
  class="mydrawer__base-hide col s12 row"
>
  <div
    id="sidemenu"
    class="mydrawer col s10 offset-s2 m6 offset-m6 l3 offset-l9 xl3 offset-xl9"
    use:styling={{ background: managementColors.general.colorAlternative }}
  >
    {#if features.length > 0}
      <div
        class="header__side col s12 row {managementColors.general.colorMode ==
        'white'
          ? 'header__side-border--white'
          : managementColors.general.colorMode == 'dark'
            ? 'header__side-border--dark'
            : ''}"
      >
        <div class="mydrawer__cont__text col s6">
          <span
            class="textheader {$locale}"
            use:styling={{ color: colorLetter }}
          >
            {$_("Welcome")}
          </span>
        </div>
        <div class="mydrawer__cont-close col s6">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="button__circle"
            use:styling={{
              background: managementColors.general.backgroundButtonCircle,
            }}
            on:click={() => closeMenuSidebar()}
          >
            <i
              class="material-icons mydrawer__icon-close"
              use:styling={{ color: colorLetter }}>close</i
            >
          </div>
        </div>
      </div>

      <div class="mydrawer__cont row col s12">
        <div
          class="mydrawer__links col s12 {managementColors.general.colorMode ==
          'white'
            ? 'mydrawer__links-border--white'
            : managementColors.general.colorMode == 'dark'
              ? 'mydrawer__links-border--dark'
              : ''}"
        >
          {#if features.find((r) => r.codename === "requests.create")}
            <div class="links__btn col s12">
              <button
                class="btn btn-success button--createrequest"
                use:styling={{ background: managementColors.color }}
                on:click={() =>
                  goFeaure(
                    features.find((r) => r.codename === "requests.create")
                  )}
              >
                <span
                  id="textcreate"
                  class="textbutton {$locale}"
                  use:styling={{
                    color: managementColors.general.colorLetterBaseButton,
                  }}
                >
                  {$_("Create request")}
                </span>
              </button>
            </div>
          {/if}

          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="links col s12"
            on:click={() => goFeaure({ codename: "home" })}
          >
            <span
              on:mouseover={() => hoverLinks("101")}
              on:mouseout={() => handleMouseOutLinks("101")}
              id="101"
              class="textLink {$locale}"
              use:styling={{ color: colorLetter }}
            >
              {$_("Home")}
            </span>
          </div>

          {#if requestsList && requestsList.length >= 1}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              class="links col s12"
              on:click={() => goFeaure({ codename: "myrequests" })}
            >
              <!-- svelte-ignore a11y-mouse-events-have-key-events -->
              <span
                on:mouseover={() => hoverLinks("100")}
                on:mouseout={() => handleMouseOutLinks("100")}
                id="100"
                class="textRequest {$locale}"
                use:styling={{ color: colorLetter }}
              >
                {$_("My experiences")}
              </span>
              {#if requestsPending >= 1}
                <div class="badge">
                  <span class="badge__number"> {requestsPending} </span>
                </div>
              {/if}
            </div>
          {/if}

          <div class="cont-links col s12">
            {#each features as feat, i}
              {#if feat.codename != "requests.create"}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div class="links col s12" on:click={() => goFeaure(feat)}>
                  <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                  <span
                    on:mouseover={() => hoverLinks(i)}
                    on:mouseout={() => handleMouseOutLinks(i)}
                    id={i}
                    class="textLink"
                    use:styling={{ color: colorLetter }}
                  >
                    {feat.caption}
                  </span>
                </div>
              {/if}
            {/each}
          </div>
        </div>
        {#if languageActual}
          <div class="mydrawer__config col s12">
            <div class="links__btn col s12">
              <i
                on:mouseover={() => hoverLinks("102")}
                on:mouseout={() => handleMouseOutLinks("102")}
                id="102"
                class="material-icons translate__icon"
                use:styling={{ color: colorLetter }}
              >
                translate
              </i>
              <span
                on:mouseover={() => hoverLinks("103")}
                on:mouseout={() => handleMouseOutLinks("103")}
                id="103"
                class="textLanguage {$locale}"
                use:styling={{ color: colorLetter }}
              >
                {$_("Language")}
              </span>
              <div
                id="drop"
                class=" dropdown"
                use:styling={{
                  background: managementColors.general.colorAlternative,
                }}
                use:styling={{
                  border: `1px solid${managementColors.color}`,
                }}
                on:click={() => (dropLanguage = dropLanguage ? false : true)}
              >
                <!-- svelte-ignore a11y-missing-attribute -->
                <a
                  class="dropdown__text"
                  use:styling={{ color: managementColors.color }}
                >
                  {languageActual}
                </a>
                <i
                  class="material-icons dropdown__icon"
                  use:styling={{ color: managementColors.color }}
                >
                  keyboard_arrow_down
                </i>
              </div>

              <ul class={dropLanguage ? "inDropDownList" : "outDropDownList"}>
                {#each languages as language, i}
                  <li
                    class="dropdown__item"
                    on:click={() => changeLanguage(language)}
                  >
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a class="dropdown__itemtext">{language.name}</a>
                  </li>
                {/each}
              </ul>
            </div>
            <div class="links__sesion col s12">
              <i
                class="material-icons icon-sesion"
                on:click={sesionAction}
                use:styling={{ color: managementColors.color }}
              >
                power_settings_new
              </i>
              {#if !auth}
                <span
                  class="textsesion {$locale}"
                  use:styling={{ color: managementColors.color }}
                  on:click={sesionAction}>{$_("Login")}</span
                >
              {:else}
                <span
                  class="textlogout {$locale}"
                  use:styling={{ color: managementColors.color }}
                  on:click={sesionAction}>{$_("Logout")}</span
                >
              {/if}
            </div>
          </div>
        {/if}
      </div>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="footermenu col s12"
        on:click={() => goFeaure({ codename: "home" })}
        use:styling={{ background: managementColors.general.colorAlternative }}
      >
        <span class="footer__text" use:styling={{ color: colorLetter }}>
          Powered by
        </span>
        <!-- svelte-ignore a11y-missing-attribute -->
        <img class="footer__logo" src={managementColors.general.iconFotter} />
      </div>
    {:else}
      <Reloading bind:managementColors={propEmpty} />
    {/if}
  </div>
  {#if showModal && mobileScreen}
    <ModalMobile
      title="Do you want to logout?"
      message="When you logout you will not able to no create new requests or see the status of old requests."
      returnText="Go back"
      actionText="Logout"
      bind:managementColors
      bind:colorLetter
      bind:closeModal={handleModal}
      bind:onAction={onLogOut}
    />
  {:else if showModal && !mobileScreen}
    <ModalDesktop
      title="Do you want to logout?"
      message="When you logout you will not able to no create new requests or see the status of old requests."
      returnText="Go back"
      actionText="Logout"
      bind:managementColors
      bind:colorLetter
      bind:closeModal={handleModal}
      bind:onAction={onLogOut}
    />
  {/if}
</div>

<style>
  .mydrawer__base-hide {
    display: none; /* Hidden by default */
  }

  .mydrawer__base {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 101; /* Sit on top */
    left: 0 !important;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    padding: 0px !important;
  }

  .mydrawer {
    display: block !important;
    position: relative;
    overflow: hidden;
    z-index: 102 !important;
    height: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000003d;
    opacity: 1;
    float: right;
    padding: 0px !important;
    overflow-y: auto;
  }

  .mydrawer__cont-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: auto;
  }

  .mydrawer__cont__text {
    display: flex;
    align-items: center;
    height: 52px;
  }

  .mydrawer__icon-close {
    font-size: 24px !important;
    letter-spacing: 0px;
    color: #686c6d;
    opacity: 1;
    cursor: pointer;
  }

  .mydrawer__cont {
    padding: 0px !important;
    height: 90%;
  }

  .footermenu {
    opacity: 80%;
    position: fixed;
    bottom: 0;
    height: 8%;
    display: flex;
    align-items: center;
    padding-left: 32px;
  }

  .footer__text {
    font-size: 14px;
    color: #686c6d;
    opacity: 1;
  }

  .footer__logo {
    height: 20px;
    margin-left: 8px;
  }

  .header__side {
    display: flex;
    align-items: center;
    height: 93px;
    padding: 16px 0px 16px 0px !important;
  }

  .header__side-border--white {
    border-bottom: 5px solid #e3e3e3;
  }

  .header__side-border--dark {
    border-bottom: 5px solid #686c6d;
  }

  .textheader {
    letter-spacing: 0px;
    color: #212121;
    font-size: 18px;
    font-weight: 700;
    opacity: 1;
  }

  .mydrawer__links {
    min-height: 100px;
    padding: 0px 0px 0px 0px !important;
    padding-bottom: 24px !important;
  }

  .mydrawer__links-border--white {
    border-bottom: 5px solid #e3e3e3;
  }

  .mydrawer__links-border--dark {
    border-bottom: 5px solid #686c6d;
  }

  .mydrawer__config {
    padding: 0px 0px 90px 16px !important;
  }

  .button--createrequest {
    width: 100%;
    height: 38px;
    background: #1ab394 0% 0% no-repeat padding-box;
    box-shadow: none !important;
    border-radius: 4px;
    opacity: 1;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;
    margin-left: 16px;
    padding: 0px;
  }

  .links__btn {
    display: flex;
    padding-top: 24px !important;
    padding-left: 0px !important;
    align-items: center;
  }

  .links {
    padding: 24px 24px 0px 16px !important;
    cursor: pointer;
  }

  .textbutton {
    letter-spacing: 0px;
    font-size: 16px;
    font-weight: 700;
    text-transform: initial;
    color: #ffffff;
    opacity: 1;
  }

  .dropdown {
    border: 1px solid #1ab394;
    border-radius: 4px;
    min-width: 55px;
    width: auto;
    height: 27px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    cursor: pointer;
  }

  .button--select {
    background: #1ab394 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #1bb39452 !important;
    border-radius: 4px;
    opacity: 1;
    align-items: center;
    cursor: pointer;
  }

  .textbuttonbase {
    letter-spacing: 0px;
    font-size: 14px;
    text-transform: uppercase;
    color: #212121;
    opacity: 1;
  }

  .textbuttonselect {
    letter-spacing: 0px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 1;
  }

  .links__sesion {
    display: flex;
    align-items: center;
    padding-top: 28px !important;
    padding-left: 0px !important;
    cursor: pointer;
  }

  .icon-sesion {
    font-size: 18px;
    letter-spacing: 0px;
    color: #686c6d;
    margin-right: 4px;
    opacity: 1;
  }

  .textsesion {
    letter-spacing: 0px;
    font-size: 14px;
    font-weight: 700;
    color: #1ab394;
    opacity: 1;
  }

  .textlogout {
    letter-spacing: 0px;
    font-size: 14px;
    font-weight: 700;
    color: #686c6d;
    opacity: 1;
  }

  .textLink {
    letter-spacing: -0.04px;
    color: #686c6d;
    opacity: 1;
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .textRequest {
    letter-spacing: -0.04px;
    color: #686c6d;
    opacity: 1;
    font-size: 14px;
    font-weight: 700;
  }

  .translate__icon {
    font-size: 18px;
    color: #686c6d;
  }

  .textLanguage {
    font-size: 14px;
    color: #686c6d;
    font-weight: 700;
    margin: 0px 8px 0px 4px !important;
  }

  .dropdown__icon {
    font-size: 15px;
    color: #1ab394;
  }

  .dropdown__text {
    font-size: 14px;
    color: #1ab394;
    margin-right: 4px;
    text-transform: uppercase;
  }

  .dropdown__itemtext {
    font-size: 16px;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .badge {
    width: 20px;
    height: 20px;
    background: #ff4855 0% 0% no-repeat padding-box;
    border-radius: 50%;
    position: relative;
    float: right;
    display: flex;
    top: 3px;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }

  .badge__number {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #ffffff;
    opacity: 1;
  }

  .cont-links {
    padding: 0px !important;
  }

  .button__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    width: 36px;
    height: 36px;
    background: #e3e3e3;
    border-radius: 50%;
    margin-right: 8px !important;
    cursor: pointer;
  }
</style>

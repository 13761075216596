<script>
  import { _, locale } from "svelte-i18n";
  import PropertyFeatures from "../../property-features-service";
  import ValidationUser from "../../authentication-service";
  import CodeValidation from "../code-validation.svelte";
  import Form from "../form-component.svelte";
  import ButtonReloading from "../../components/button-loading/component.svelte";
  import { createEventDispatcher } from "svelte";

  export let state;
  export let managementColors;

  const dispatch = createEventDispatcher();
  let valrequired = false;
  let propertyData = {};
  let blockButton = true;
  let dataLogin = {};
  let index = 0;
  let loginError = false;
  let colorPage;
  let textBtn = "Continue";
  let settingsData;
  let arrayWays = [
    {
      id: 0,
      text: "I don’t have a validation code",
    },
    {
      id: 1,
      text: "I don’t have a room number",
    },
    {
      id: 2,
      text: "I don’t have a reservation",
    },
    {
      id: 3,
      text: "",
    },
  ];

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors"),
        ))
      : (managementColors.color = "#2bbbad");

    managementColors.general.backgroundColor != "#FFFFFF"
      ? (colorPage = "#2C2C2C")
      : (colorPage = "#2F4050");
  };

  validationColor();

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const callLanguageActive = () => {
    if (localStorage.getItem("language")) {

       locale.set((localStorage.getItem("language")));
    } else {
      locale.set(window.navigator.language.split("-")[0]);
    }
  };

  callLanguageActive();

  Promise.all([
    PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
    PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
  ]).then(([propertyInformation, guestappsettins]) => {
    propertyData = propertyInformation;
    settingsData = guestappsettins;
    setFontFamily(settingsData.fontFamily);
  });

  let resetBtnVariables = (blockButton, loginError) => {};

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  let dataUser = (data) => {
    switch (index) {
      case 0:
        dataLogin.code = data;
        data.length == 5 ? (blockButton = false) : (blockButton = true);
        resetBtnVariables(blockButton, loginError);
        break;
      case 1:
        dataLogin.firstName = data.name;
        dataLogin.lastName = data.lastname;
        dataLogin.roomNumber = data.roomnumber;

        data.name &&
        data.name.length > 2 &&
        data.lastname &&
        data.lastname.length > 2 &&
        data.roomnumber &&
        data.roomnumber.length > 1
          ? (blockButton = false)
          : (blockButton = true);

        resetBtnVariables(blockButton, loginError);
        break;
      case 2:
        dataLogin.firstName = data.name;
        dataLogin.lastName = data.lastname;
        dataLogin.checkinDate = data.date;

        data.name &&
        data.name.length > 2 &&
        data.lastname &&
        data.lastname.length > 2 &&
        data.date
          ? (blockButton = false)
          : (blockButton = true);

        resetBtnVariables(blockButton, loginError);
        break;
      default:
        break;
    }
    settingsData ? setFontFamily(settingsData.fontFamily) : undefined;
  };

  let changeWayValidation = () => {
    index++;
    valrequired = false;
    loginError = false;
    index == 3
      ? dispatch("action", {
          type: "no-validation",
        })
      : null;
  };

  let handleLogin = () => {
    valrequired = true;
    if (!blockButton) {
      blockButton = true;
      loginError = false;

      if (index == 0) {
        ValidationUser.login(state.propertyId, dataLogin)
          .then((response) => {
            if (response) {
              dispatch("action", {
                type: "ok",
              });
            } else {
              loginError = true;
              blockButton = false;
              valrequired = false;
              resetBtnVariables(blockButton, loginError);
            }
          })
          .catch((e) => {
            loginError = true;
            blockButton = false;
            valrequired = false;
            resetBtnVariables(blockButton, loginError);
          });
      } else {
        ValidationUser.sendCodeEmail(state.propertyId, dataLogin)
          .then((response) => {
            dispatch("action", {
              type: "message-send-email",
            });
          })
          .catch((e) => {
            loginError = true;
            blockButton = false;
            resetBtnVariables(blockButton, loginError);
          });
      }
    }
  };

  let handleBack = () => {
    if (index > 0) {
      index = index - 1;
      valrequired = false;
      loginError = false;
    } else {
      dispatch("action", {
        type: "close",
      });
    }
  };
</script>

<div
  class="col s12 validation-page row"
  use:styling={{ background: colorPage }}
>
  <div class="validation-code__header">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <i class="material-icons icon-back" on:click={handleBack}>arrow_back</i>
  </div>
  <div class="col s12 validation" use:styling={{ background: colorPage }}>
    <div class="validation__card col m6 offset-m3 l4 offset-l4 s12">
      <div class="validation-code__logocont">
        <!-- svelte-ignore a11y-missing-attribute -->
        <img class="validation-code__logo" src={propertyData.logo} />
      </div>
      <div
        class="validation-code__body row"
        use:styling={{ background: managementColors.general.backgroundColor }}
      >
        <div class="col l12 m12 s12 form-section">
          <div class="col s12 title">
            <span
              class="title__text"
              use:styling={{ color: managementColors.general.colorLetter }}
            >
              {propertyData.name}
            </span>
          </div>

          {#if index == 0}
            <CodeValidation
              bind:completeCode={dataUser}
              bind:valrequired
              bind:loginError
              bind:managementColors
            />
          {:else}
            <Form
              bind:wayValidation={index}
              bind:dataForm={dataUser}
              bind:valrequired
              bind:loginError
              bind:managementColors
            />
          {/if}

          <div class="col s12 not-code">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <span
              class="not-code__text {$locale}"
              use:styling={{ color: managementColors.general.colorLetter }}
              for="guest"
              on:click={changeWayValidation}
            >
              {$_(arrayWays[index].text)}
            </span>
            <div
              class="col s12 validation-page__footer"
              use:styling={{
                background: managementColors.general.backgroundColor,
              }}
            ></div>
          </div>

          <div class="col s12 validation-page__footer__desktop">
            <div class="bottonNext">
              <div class="col s12 bt__cont button">
                <ButtonReloading
                  bind:handleLogin
                  bind:resetBtnVariables
                  bind:managementColors
                  bind:textBtn
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .bottonNext {
    margin-right: 20%;
    margin-top: 10%;
  }
  .bt__cont {
    width: 85% !important;
  }

  .validation__card {
    height: 100%;
  }

  .validation-page {
    height: 100%;
    margin: 0px;
  }

  .validation-code__header {
    background: transparent;
    width: 100%;
    display: flex;
    position: fixed;
  }

  .validation {
    padding: 0px !important;
    height: auto;
    display: flex;
    align-items: center;
  }

  .validation-code__logocont {
    height: 126px;
    width: 126px;
    position: relative;
    top: 80px;
    z-index: 9;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 4px;
    opacity: 1;
    margin: auto;
    padding: 0px;
  }

  .validation-code__logo {
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
  }

  .form-section {
    padding-bottom: 87px !important;
  }

  .validation-code__body {
    height: 80%;
    border-radius: 20px 20px 0px 0px;
    min-height: 377px;
    opacity: 1;
    position: relative;
    margin-bottom: 0px;
    padding-top: 100px;
  }

  .body__title {
    font-size: 0.875rem;
    font-weight: 700;
    color: #212121;
    margin-bottom: 17px;
  }

  .title {
    text-align: center;
    padding-bottom: 16px !important;
    text-align: center;
  }

  .tetx__required {
    font-size: 0.75rem;
    color: #ff4855;
  }

  .label__input {
    left: 0 !important;
    text-transform: uppercase;
    color: #686c6d !important;
    font-weight: 700;
  }

  .divlogo {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 13px;
    line-height: 1;
  }

  .required {
    color: #ff4855;
  }

  .not-code {
    text-align: center;
    padding-bottom: 24px !important;
  }

  .not-code__text {
    font-size: 0.875rem;
    color: #686c6d;
    font-weight: 700;
    cursor: pointer;
  }

  .title__text {
    font-size: 1.125rem;
    font-weight: 700;
    opacity: 1;
  }

  .loader {
    background: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    text-align: center;
  }

  .validation-page__footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    display: contents;
    height: 11%;
    padding: 0px 12px 0px 12px;
  }

  /* .validation-page__footer__desktop {
    display: none;
  } */

  .icon-back {
    font-size: 1.5rem;
    color: white;
    float: left;
    cursor: pointer;
    margin: 12px 0px 0px 10px !important;
  }

  @media only screen and (min-width: 601px) {
    /* .bottonNext {
      margin-right: 10rem;
    } */

    .validation-page__footer {
      position: fixed;
      width: 100%;
      bottom: 0;
      height: 11%;
      padding: 0px 12px 0px 12px;
    }

    .validation-code__body {
      height: auto;
      padding-top: 112px;
    }

    .validation-code__logocont {
      min-height: 160px;
      width: 160px;
      padding: 0px;
    }

    .validation-page__footer__desktop {
      display: block;
      height: 110px;
    }

    .validation-page__footer {
      display: none;
    }

    .divlogo {
      padding-top: 17px;
    }

    .title {
      padding-bottom: 24px !important;
    }

    .form-section {
      padding-bottom: 0px !important;
    }

    .validation__card {
      padding-bottom: 30px !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .validation-code__body {
      border-radius: 20px 20px 20px 20px;
      height: auto;
      min-height: 0px;
    }

    .validation-code__logocont {
      align-items: center;
    }

    .title__text {
      font-size: 1.5rem;
    }
  }

  .dsp__mid {
    display: flex;
    justify-content: center;
  }
</style>



export function setLanguage(language) {
    const storedLanguage = localStorage.getItem("language");
    localStorage.setItem("language", language ? language : storedLanguage ? storedLanguage : "en".toString())
    return Promise.resolve()
}

export function getCurrentLanguage() {
    const language = localStorage.getItem("language")
    if (!language) {
        return setLanguage("en").then(() => "en")
    }
    return Promise.resolve(language)
}
<script>
  import { _, locale } from "svelte-i18n";
  import { push, location } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import RoutesService from "../../management-routes-service";
  import ValidationUser from "../../authentication-service";
  import Sidebar from "./sidebar-component.svelte";
  import FeedbackModal from "./feedback.modal.svelte";

  export let logoUrl = "";
  export let propertyName = "";
  export let propertyId;
  export let listLanguagesEnabled;
  export let features;
  export let managementColors;
  export let iframeFromLanding;
  export let showBtnBack;
  export let languageConection;
  export let backgroundColorView;
  export let colorLetter;
  export let lengthNamesPropery;
  export let color;
  export let outPutUpdateData = () => {};
  export let requestsPendingOutput = () => {};

  const styleSettings = {
    "--dynamic-color": managementColors.color,
    "--filter-svg": managementColors.filter
  };
  Object.keys(styleSettings).forEach((key) =>
    document.querySelector(":root").style.setProperty(key, styleSettings[key])
  );

  let requestsPending = 0;
  let showsidebar = false;
  let requestsList;
  let feedbackListActives = [];
  let reservationId = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth")).reservationId
    : null;
  let browserIdentify = JSON.parse(localStorage.getItem("browser-identify"));
  let threeDots = false;
  let dataHeader = {};
  let filterSvg = false;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const init = () => {
    if (
      window.location.host === "reservations.app.guesthub.io" &&
      parseInt(propertyId) === 1
    ) {
      window.location.href = window.location.href.replace("app", "properties");
    }

    !localStorage.getItem("browser-identify")
      ? localStorage.setItem("browser-identify", new Date().getTime())
      : null;

    if (window.location.href.includes("language=")) {
      localStorage.setItem(
        "language",
        JSON.stringify(window.location.href.split("language=")[1])
      );
      locale.set(window.location.href.split("language=")[1]);
    }

    const code = window.location.href.includes("?code=")
      ? window.location.href.split("code=")[1].includes("&")
        ? window.location.href.split("code=")[1].split("&")[0]
        : window.location.href.split("code=")[1]
      : null;
    (code
      ? ValidationUser.loginMagicLink(propertyId, { code })
      : ValidationUser.isLogIn(propertyId).catch(() => true)
    ).then(() => {
      Promise.all([
        PropertyFeatures.requestList({
          propertyId: propertyId,
          reservationId: reservationId,
          browserIdentify
        }),
        PropertyFeatures.inStaFeedbackListActives({
          propertyId: propertyId,
          reservationId: reservationId,
          browserIdentify
        }),
        PropertyFeatures.eventsEndpoint({
          propertyId: propertyId,
          reservationId: reservationId
        })
      ]).then(([myRequests, myFeedbackActives, eventSource]) => {
        requestsList = myRequests;
        feedbackListActives = myFeedbackActives;

        eventSource.addEventListener(
          "guesthub:in-stay-feedback:updated",
          (event) => {
            const ev = JSON.parse(event.data);
            if (feedbackListActives.map((r) => r.id).indexOf(ev.id) > -1) {
              if (ev.state == "active") {
                feedbackListActives[
                  feedbackListActives.map((r) => r.id).indexOf(ev.id)
                ] = ev;
              } else {
                feedbackListActives.splice(
                  feedbackListActives.map((r) => r.id).indexOf(ev.id),
                  1
                );
              }
            } else {
              if (ev.state == "active") {
                feedbackListActives.unshift(ev);
              }
            }
            feedbackListActives = feedbackListActives;
            outPutUpdateData("create", ev.id);
          }
        );

        eventSource.addEventListener(
          "guesthub:in-stay-feedback:created",
          (event) => {
            const ev = JSON.parse(event.data);
            if (feedbackListActives.map((r) => r.id).indexOf(ev.id) > -1) {
              if (ev.state == "active") {
                feedbackListActives[
                  feedbackListActives.map((r) => r.id).indexOf(ev.id)
                ] = ev;
              } else {
                feedbackListActives.splice(
                  feedbackListActives.map((r) => r.id).indexOf(ev.id),
                  1
                );
              }
            } else {
              if (ev.state == "active") {
                feedbackListActives.unshift(ev);
              }
            }
            feedbackListActives = feedbackListActives;
          }
        );

        eventSource.addEventListener("request:updated", (event) => {
          const ev = JSON.parse(event.data);
          if (requestsList.map((r) => r.id).indexOf(ev.id) > -1) {
            requestsList[requestsList.map((r) => r.id).indexOf(ev.id)] = ev;
          } else {
            requestsList.unshift(ev);
          }
          requestsList = requestsList;
          requestsPending = requestsList.filter(
            (req) =>
              req.status == "Validate" ||
              req.status == "Accepted" ||
              req.status == "Processed"
          ).length;
          requestsPendingOutput(requestsList);
          outPutUpdateData("single", ev.id);
        });

        eventSource.addEventListener("request:created", (event) => {
          const ev = JSON.parse(event.data);
          if (requestsList.map((r) => r.id).indexOf(ev.id) > -1) {
            requestsList[requestsList.map((r) => r.id).indexOf(ev.id)] = ev;
          } else {
            requestsList.unshift(ev);
          }
          requestsList = requestsList;
          requestsPending = requestsList.filter(
            (req) =>
              req.status == "Validate" ||
              req.status == "Accepted" ||
              req.status == "Processed"
          ).length;
          requestsPendingOutput(requestsList);
          outPutUpdateData("single", ev.id);
        });

        eventSource.addEventListener("request:created-multiples", (event) => {
          const ev = JSON.parse(event.data);
          outPutUpdateData("double", ev.id);
        });

        requestsPending = requestsList.filter(
          (req) =>
            req.status == "Validate" ||
            req.status == "Accepted" ||
            req.status == "Processed"
        ).length;
        requestsPendingOutput(requestsList);
      });

      if (localStorage.getItem("language") && listLanguagesEnabled.length > 1) {
        locale.set(localStorage.getItem("language"));
      } else if (listLanguagesEnabled.length == 1) {
        locale.set(listLanguagesEnabled[0].toLowerCase());

        localStorage.setItem(
          "language",
          JSON.stringify(listLanguagesEnabled[0].toLowerCase())
        );
      } else {
        locale.set(window.navigator.language.split("-")[0]);
      }

      validationLengthNamesProperty();

      features.sort((a, b) => a.numOrder - b.numOrder);
    });
  };

  let getDataHeader = () => {
    localStorage.getItem("dataHeader")
      ? (dataHeader = JSON.parse(localStorage.getItem("dataHeader")))
      : (dataHeader = null);
    validationSvg(dataHeader);
  };

  let validationSvg = () => {
    if (
      dataHeader &&
      dataHeader.imgFeature &&
      dataHeader.imgFeature.split("/").find((sp) => sp == "img")
    ) {
      filterSvg = true;
    } else {
      filterSvg = false;
    }
  };

  let handleSidebar = () => {
    const body = document.body;
    if (!showsidebar && !iframeFromLanding) {
      showsidebar = true;
      body.style.overflowY = "auto";
    } else {
      showsidebar = false;
      body.style.overflowY = "auto";
    }
  };

  let refresh = () => {
    requestsPendingOutput(0);
    requestsPending = 0;
  };

  let handleBack = () => {
    RoutesService.goLastRoute(propertyId);
  };

  let handleOrigin = () => {
    if (
      dataHeader.link.split("list-items").length > 1 ||
      dataHeader.codeName == "restaurants.list" ||
      dataHeader.codeName == "room-service.list"
    ) {
      push(dataHeader.link);
    } else {
      push(`/properties/${propertyId}`);
    }
  };

  let handleLanguage = (e) => {
    init();
    languageConection(e);
  };

  let validationLengthNamesProperty = () => {
    if (propertyName.length > lengthNamesPropery) threeDots = true;
  };

  init();
  getDataHeader();
</script>

{#if features && dataHeader}
  <div
    class="header row col s12"
    use:styling={{ background: managementColors.general.colorAlternative }}
  >
    <div class="header__nav col s12">
      <div class="nav__back col s1">
        {#if $location != `/properties/${propertyId}` && showBtnBack}
          <div
            class="button__circle"
            use:styling={{
              background: managementColors.general.backgroundButtonCircle
            }}
            on:click={handleBack}
          >
            <i
              class="material-icons icon-back"
              use:styling={{ color: colorLetter }}
            >
              keyboard_arrow_left
            </i>
          </div>
        {/if}
      </div>

      {#if dataHeader.nameFeature == "Home"}
        <div class="nav__one col s10">
          <img
            class="header-icon--img"
            alt="img"
            src={logoUrl}
            on:click={handleOrigin}
          />
          <span
            class="header_nameproperty"
            use:styling={{ color: colorLetter }}
            on:click={handleOrigin}
          >
            {propertyName.slice(0, lengthNamesPropery)}
          </span>
          {#if threeDots}
            <span
              class="header_nameproperty"
              use:styling={{ color: colorLetter }}
            >
              ...
            </span>
          {/if}
        </div>
      {:else}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="nav__one col s10" on:click={handleOrigin}>
          {#if dataHeader.codeName == "requests.create"}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              use:styling={{ background: managementColors.color }}
              class="cont__header-icon--create"
              on:click={handleOrigin}
            >
              <img
                class={filterSvg &&
                managementColors.general.colorMode == "white"
                  ? "header-icon--white"
                  : filterSvg && managementColors.general.colorMode == "dark"
                    ? "header-icon--dark"
                    : "header-icon--completed"}
                alt="img"
                src={dataHeader.imgFeature}
              />
            </div>
          {:else}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="cont__header-icon" on:click={handleOrigin}>
              <img
                style={filterSvg ? "filter:" + managementColors.filter : ""}
                class:header-icon={true}
                alt="img"
                src={dataHeader.imgFeature}
              />
            </div>
          {/if}
          <span
            class="header_nameproperty"
            use:styling={{ color: colorLetter }}
            on:click={handleOrigin}
          >
            {dataHeader.nameFeature
              ? $_(dataHeader.nameFeature).slice(0, lengthNamesPropery)
              : ""}
          </span>
          {#if threeDots}
            <span
              class="header_nameproperty"
              use:styling={{ color: colorLetter }}
            >
              ...
            </span>
          {/if}
        </div>
      {/if}

      <div class="nav__two col s1">
        <div class="icon-menu__container col s12">
          {#if requestsPending >= 1}
            <div class="badge">
              <span class="badge__number"> {requestsPending} </span>
            </div>
          {/if}
          {#if features.length > 0}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <i
              class="material-icons icon-menu"
              use:styling={{ color: colorLetter }}
              on:click={handleSidebar}
            >
              menu
            </i>
          {/if}
        </div>
      </div>
    </div>

    {#if showsidebar}
      <Sidebar
        bind:closeMenuSidebar={handleSidebar}
        bind:handleLanguage
        bind:refresh
        bind:propertyId
        bind:logoUrl
        bind:listLanguagesEnabled
        bind:requestsPending
        bind:features
        bind:managementColors
        bind:requestsList
        bind:backgroundColorView
        bind:colorLetter
      />
    {/if}
  </div>

  {#each feedbackListActives as feedback}
    <FeedbackModal
      id={feedback.id}
      propertyId="{propertyId},"
      {reservationId}
      browserIdentify{browserIdentify}
      logoProperty={logoUrl}
      {propertyName}
      {managementColors}
    ></FeedbackModal>
  {/each}
{/if}

<style>
  .header {
    margin-bottom: 0px !important;
    display: flex;
    flex-wrap: wrap;
    padding: 0px !important;
  }

  .icon-menu__container {
    padding: 0px !important;
  }

  .header__nav {
    margin: 0px;
    height: 60px;
    display: flex;
    align-items: center;
    z-index: 100 !important;
    padding: 0px !important;
  }

  .icon-menu {
    font-size: 1.5rem;
    color: #212121;
    float: right;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 12px;
  }

  .icon-back {
    font-size: 1.5rem;
    color: #2f4050;
    float: left;
    cursor: pointer;
  }

  .header_nameproperty {
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  .header-icon {
    width: 28px;
    height: 28px;
    position: relative;
    border-radius: 4px !important;
    opacity: 1;
    cursor: pointer;
    object-fit: cover;
  }

  .header-icon--img {
    width: 28px;
    height: 28px;
    position: relative;
    border-radius: 4px !important;
    opacity: 1;
    margin-right: 16px;
    cursor: pointer;
  }

  .header-icon--completed {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px !important;
    opacity: 1;
    cursor: pointer;
    box-shadow: 0px 4px 8px #00000029;
  }

  .header-icon--white {
    width: 28px;
    height: 28px;
    filter: invert(100%) sepia(6%) saturate(755%) hue-rotate(169deg)
      brightness(117%) contrast(100%);
  }

  .header-icon--dark {
    width: 28px;
    height: 28px;
    filter: invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg)
      brightness(102%) contrast(102%);
  }

  .nav__one {
    display: flex;
    align-items: center;
    /* padding-left: 0px !important; */
    justify-content: center;
    text-align: center;
    pointer-events: none;
  }

  .nav__two {
    padding: 0px !important;
  }

  .badge {
    width: 15px;
    height: 15px;
    background: #ff4855 0% 0% no-repeat padding-box;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    top: 15px;
    right: 8px;
    z-index: 10;
  }

  .badge__number {
    font-size: 0.5rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  .nav__back {
    margin: 0px !important;
  }

  .button__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 8px !important;
    cursor: pointer;
  }

  .cont__header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px;
    margin-left: 26px;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 1;
  }
  @media only screen and (min-width: 20px) and (max-width: 400px) {
    .cont__header-icon {
      margin-right: 9px;
      margin-left: 26px;
    }

    .header_nameproperty {
      display: inline-block;
      /* width: 71%; */
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
    }
  }

  .cont__header-icon--create {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 16px;
    opacity: 1;
    cursor: pointer;
  }

  .header-icon--create {
    width: 28px;
    height: 28px;
    border-radius: 4px !important;
    filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(23deg)
      brightness(110%) contrast(96%) !important;
    opacity: 1;
  }
</style>

<script>
  import { _, locale } from "svelte-i18n";
  import { push, location } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import RoutesServices from "../../management-routes-service";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import environments from "../../environments";

  export let params = {};

  let linkIframe = null;
  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let languagesEnabled = [];
  let language;
  let faeturesList = [];
  let managementColors = {};
  let showBtnBack = true;
  let propEmpty = "";
  let reload = true;
  let dataHeader = JSON.parse(localStorage.getItem("dataHeader"));
  let veiliframe = false;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");
  };

  validationColor();

  let constructorIframe = (lang, entryPoint, fontFamily) => {
    var auth = JSON.parse(localStorage.getItem("auth"));
    var reservation = JSON.parse(localStorage.getItem("reservation"));
    var browserIdentify = JSON.parse(localStorage.getItem("browser-identify"));

    if (auth && reservation) {
      var queryParams = `?iframe=detailmenu&lang=${lang.toUpperCase()}&fontFamily=${encodeURIComponent(
        fontFamily
      )}&guestName=${encodeURIComponent(
        auth.guestName
      )}&roomNumber=${encodeURIComponent(
        auth.roomNumber
      )}&reservation=${reservation}&reservationId=${encodeURIComponent(
        auth.reservationId
      )}&reservation=${reservation}&reservationVip=${encodeURIComponent(
        auth.reservationVip
      )}&browserIdentify=${encodeURIComponent(
        browserIdentify
      )}&serviceId=${encodeURIComponent(dataHeader.featureId)}`;
    } else {
      var queryParams = `?iframe=detailmenu&lang=${lang.toUpperCase()}&fontFamily=${encodeURIComponent(
        fontFamily
      )}&browserIdentify=${encodeURIComponent(
        browserIdentify
      )}&serviceId=${encodeURIComponent(dataHeader.featureId)}`;
    }

    if (!entryPoint) {
      var route = `${environments.guestsServices.web}/#/properties/${
        state.propertyId
      }/menus/${JSON.parse(localStorage.getItem("menuId"))}`;
    } else {
      var route = `${environments.guestsServices.web}/#${entryPoint}`;
    }

    linkIframe =
      ' <iframe id="jqt"   src="' +
      route +
      queryParams +
      '"  frameborder="0"   style=" padding:0px; height: 100% !important; position: relative !important; background:black !important;" class="col s12 l12 xl12" ></iframe>';

    setTimeout(() => {
      if (linkIframe) reload = false;
    }, 2000);
  };

  const init = (lang, entryPoint) => {
    setTimeout(() => {
      Promise.all([
        PropertyFeatures.list({ propertyId: state.propertyId }, lang),
        PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
        PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
      ]).then(([features, guestappsettins, propertyInformation]) => {
        propertyData = propertyInformation;
        faeturesList = features.filter(
          (fet) => fet.enabled || fet.codename == "property.details"
        );
        constructorIframe(
          lang || propertyData.language.code,
          entryPoint,
          guestappsettins.fontFamily
        );
        validationLanguage(
          guestappsettins.languages,
          propertyInformation.language.code
        );
        setFontFamily(guestappsettins.fontFamily);
        localStorage.setItem("lastRoute", '"pop"');
      });
    }, 200);
  };

  !dataHeader.linkLogin
    ? init(localStorage.getItem("language"), false)
    : init(
        localStorage.getItem("language"),
        JSON.parse(localStorage.getItem("entryPoint"))
      );

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  const connectioWhitIframe = () => {
    window.addEventListener("message", function (event) {
      if (event.data.info) {
        switch (event.data.info.type) {
          case "back":
            let amountMenus = JSON.parse(localStorage.getItem("amountMenus"));
            amountMenus > 1 || event.data.info.value == "pop"
              ? RoutesServices.setLastRoute(event.data.info.value)
              : RoutesServices.setLastRoute("viewlanding");
            break;
          case "go":
            if (event.data.info.value == "viewlogin") {
              dataHeader.linkLogin = $location;
            } else if (event.data.info.value == "viewrequestcomplete") {
              var auth = JSON.parse(localStorage.getItem("auth"));

              push(
                `/properties/${state.propertyId}/my-experience/${event.data.info.requestId}`
              );
            } else {
              dataHeader.link = `/properties/${state.propertyId}`;
            }

            localStorage.setItem("dataHeader", JSON.stringify(dataHeader));
            RoutesServices.setLastRoute(event.data.info.value);
            RoutesServices.goRoute(state.propertyId, event.data.info.requestId);
            break;
          case "entryPoint":
            RoutesServices.setEntryPoint(event.data.info.value);
            break;
          case "veiliframe":
            event.data.info.value ? (veiliframe = true) : (veiliframe = false);
            break;
          case "redirectToGA":
            push(
              `${event.data.info.value.replace(
                window.location.origin + "/#",
                ""
              )}`
            );
            break;
          default:
            break;
        }
      }
    });
  };

  connectioWhitIframe();

  const validationLanguage = (enabledLanguges, languageDefault) => {
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  let setRoute = () => {
    RoutesServices.setLastRoute("viewlanding");
  };

  let handleLanguage = (e) => {
    init(e, JSON.parse(localStorage.getItem("entryPoint")));
  };

  setRoute();
</script>

<div
  use:styling={{ background: managementColors.general.backgroundColor }}
  class="container-fluid row"
>
  {#if !veiliframe}
    <div
      class="col s12 row navifation-cont {managementColors.general.colorMode ==
      'white'
        ? 'navifation-cont-border--white'
        : managementColors.general.colorMode == 'dark'
          ? 'navifation-cont-border--dark'
          : ''}"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      <NavigationTop
        bind:languageConection={handleLanguage}
        bind:logoUrl={propertyData.logo}
        bind:propertyName={propertyData.name}
        bind:propertyId={state.propertyId}
        bind:lang={language}
        bind:listLanguagesEnabled={languagesEnabled}
        bind:features={faeturesList}
        bind:backgroundColorView={managementColors.general.backgroundColor}
        bind:colorLetter={managementColors.general.colorLetter}
        bind:managementColors
        bind:showBtnBack
        bind:iframeFromLanding={propEmpty}
      />
    </div>
  {/if}

  <div
    use:styling={{ background: managementColors.general.backgroundColor }}
    class="col s12 l12 xl12 iframe"
    contenteditable="false"
    bind:innerHTML={linkIframe}
  />

  {#if reload}
    <div
      use:styling={{ background: managementColors.general.backgroundColor }}
      class="reloading row col s12"
    >
      <Reloading bind:managementColors />
    </div>
  {/if}
</div>

<style>
  .reloading {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .container-fluid {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .iframe {
    padding: 0px !important;
    height: 90% !important;
    flex: 1;
  }
</style>

<script>
  import { _, locale } from "svelte-i18n";
  import { push, location } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import FileViewer from "../../components/file-viewer/component.svelte";
  import LinkViewer from "../../components/link-viewer/component.svelte";
  import environments from "../../environments";
  import Reservations from "../../reservations-service";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let languagesEnabled = [];
  let language;
  let faeturesList = [];
  let managementColors = {};
  let showBtnBack = true;
  let propEmpty = "";
  let reload = true;
  let dataHeader = JSON.parse(localStorage.getItem("dataHeader"));
  let outlet;
  let fileUrl = undefined;
  let contentUrl = undefined;
  let showContent = false;
  let openModalOffMenu = false;

  let dictionaryColors = [
    {
      title: "redfilter",
      color: "#FD0D1B",
    },
    {
      title: "orangefilter",
      color: "#FD9726",
    },
    {
      title: "yellowfilter",
      color: "#FFCC21",
    },
    {
      title: "greenfilter",
      color: "#6AA84E",
    },
    {
      title: "bluefilter",
      color: "#0A23FB",
    },
    {
      title: "violetfilter",
      color: "#9825FB",
    },
    {
      title: "purplefilter",
      color: "#FD28FC",
    },
    {
      title: "greyfilter",
      color: "#969696",
    },
    {
      title: "redwinefilter",
      color: "#A51C0E",
    },
    {
      title: "mustardfilter",
      color: "#F4B92A",
    },
    {
      title: "gcreamfilter",
      color: "#1AB394",
    },
    {
      title: "blightfilter",
      color: "#3C74D6",
    },
    {
      title: "darkvioletfilter",
      color: "#674EA6",
    },
    {
      title: "dpurplefilter",
      color: "#A74F79",
    },
    {
      title: "gdarkfilter",
      color: "#404040",
    },
    {
      title: "cdarkfilter",
      color: "#5B0F04",
    },
    {
      title: "goldfilter",
      color: "#E69239",
    },
    {
      title: "clightfilter",
      color: "#B25D16",
    },
    {
      title: "ydarkfilter",
      color: "#BF8F1F",
    },
    {
      title: "greendarkfilter",
      color: "#264F13",
    },
    {
      title: "bluetwofilter",
      color: "#1B4682",
    },
    {
      title: "bluedarkfilter",
      color: "#1F124D",
    },
    {
      title: "purpledarkfilter",
      color: "#4B102F",
    },
    {
      title: "blackcolor",
      color: "#000000",
    },
    {
      title: "ligthredfilter",
      color: "#EF9A9A",
    },
    {
      title: "ligthorangefilter",
      color: "#FFCC80",
    },
    {
      title: "ligthyellowfilter",
      color: "#FDF5CC",
    },
    {
      title: "ligthgreenfilter",
      color: "#C5E1A5",
    },
    {
      title: "ligthbluefilter",
      color: "#C7DAFA",
    },
    {
      title: "ligthvioletfilter",
      color: "#E2D9FF",
    },
    {
      title: "ligthpurplefilter",
      color: "#E8D2DC",
    },
    {
      title: "ligthgreyfilter",
      color: "#E3E3E3",
    },
    {
      title: "ligthredwinefilter",
      color: "#E06767",
    },
    {
      title: "ligthmustardfilter",
      color: "#F8B36B",
    },
    {
      title: "ligthgcreamfilter",
      color: "#FED865",
    },
    {
      title: "ligthblightfilter",
      color: "#6AA84E",
    },
    {
      title: "ligthdarkvioletfilter",
      color: "#A3C2F4",
    },
    {
      title: "ligthdpurplefilter",
      color: "#C4B1F7",
    },
    {
      title: "ligthgdarkfilter",
      color: "#FFA5D0",
    },
    {
      title: "ligthcdarkfilter",
      color: "#969696",
    },
    {
      title: "ligthgoldfilter",
      color: "#E42527",
    },
    {
      title: "ligthclightfilter",
      color: "#FF8600",
    },
    {
      title: "ligthydarkfilter",
      color: "#FFC50F",
    },
    {
      title: "ligthgreendarkfilter",
      color: "#1BB394",
    },
    {
      title: "ligthbluetwofilter",
      color: "#589AFF",
    },
    {
      title: "ligthbluedarkfilter",
      color: "#B45FFF",
    },
    {
      title: "ligthpurpledarkfilter",
      color: "#FF4EA3",
    },
    {
      title: "ligthblackcolor",
      color: "#BF8F1F",
    },
  ];

  const getColorComponentsAndIcons = (color) => {
    return dictionaryColors.find((item) => item.color == color);
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");
  };

  validationColor();

  const init = (lang, entryPoint) => {
    reload = true;
    setTimeout(() => {
      Promise.all([
        PropertyFeatures.list({ propertyId: state.propertyId }, lang),
        PropertyFeatures.guestAppSettings({
          propertyId: state.propertyId,
        }),
        PropertyFeatures.proppertyInformation({
          propertyId: state.propertyId,
        }),
        PropertyFeatures.outletDetail({
          propertyId: state.propertyId,
          outleType: window.location.hash.includes("room-service")
            ? "room-service"
            : window.location.hash.includes("other")
              ? "other"
              : "restaurant",
          language: lang,
          id: state.idOutlet,
        }),
      ]).then(
        ([features, guestappsettins, propertyInformation, outletDetail]) => {
          outlet = Object.assign({}, outletDetail, {
            description: outletDetail.description
              ? outletDetail.description.replace(/\n/g, "<br />")
              : "",
          });
          propertyData = propertyInformation;
          dataHeader = Object.assign({}, dataHeader, {
            nameFeature: outlet.name,
            imgFeature: outlet.type != "other" ? outlet.photo : outlet.cover,
          });
          localStorage.setItem("dataHeader", JSON.stringify(dataHeader));
          localStorage.setItem(
            "lastRoute",
            window.location.hash.includes("room-service")
              ? '"viewroomservice"'
              : window.location.hash.includes("service")
                ? '"viewserviceslist"'
                : '"viewrestaurantslist"'
          );
          faeturesList = features.filter(
            (fet) => fet.enabled || fet.codename == "property.details"
          );
          validationLanguage(
            guestappsettins.languages,
            propertyInformation.language.code
          );
          validationColors(guestappsettins);
          setFontFamily(guestappsettins.fontFamily);
          reload = false;
        }
      );
    }, 200);
  };

  const validationColors = (guestappsettins) => {
    managementColors = {
      filter: guestappsettins.filter,
      color: Reservations.getColorComponentsAndIcons(guestappsettins.color)
        .color,
      title: Reservations.getColorComponentsAndIcons(guestappsettins.color)
        .title,
      general: Reservations.getColorsContrast(guestappsettins),
    };

    localStorage.setItem("managementColors", JSON.stringify(managementColors));
  };

  $: if (params) {
    state = Object.assign({ features: [], reservation: undefined }, params);
    init(localStorage.getItem("language"), false);
  }
  if (dataHeader && !dataHeader.linkLogin) {
    init(localStorage.getItem("language"), false);
  } else {
    init(
      localStorage.getItem("language"),
      JSON.parse(localStorage.getItem("entryPoint"))
    );
  }

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  const validationLanguage = (enabledLanguges, languageDefault) => {
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  let handleLanguage = (e) => {
    init(e, JSON.parse(localStorage.getItem("entryPoint")));
  };

  let handleOutletMenuDetail = (data, content) => {
    switch (content.type) {
      case "file":
        fileUrl = content.link;
        showContent = true;
        break;
      case "link":
        if (content.canIframe) {
          contentUrl = content.link;
          showContent = true;
        } else {
          window.open(`${content.link}`, "_blank").focus();
        }

        break;
      case "menu":
        if (content.inService) {
          push(
            `/properties/${propertyData.id}/${data.type}/${data.id}/${content.id}`
          );
        } else {
          openModalOffMenu = Object.assign({
            property: propertyData,
            data: data,
            content: content,
          });
        }
        break;
      case "wysiwyg":
        push(
          `/properties/${propertyData.id}/${data.type}/${data.id}/${content.id}`
        );
        break;
      default:
        break;
    }
  };

  let goToMenu = () => {
    push(
      `/properties/${openModalOffMenu.property.id}/${openModalOffMenu.data.type}/${openModalOffMenu.data.id}/${openModalOffMenu.content.id}`
    );
    openModalOffMenu = false;
  };
</script>

<!-- svelte-ignore non-top-level-reactive-declaration -->
<!-- svelte-ignore non-top-level-reactive-declaration -->
<div
  use:styling={{
    background:
      managementColors &&
      managementColors.general &&
      managementColors.general.backgroundColor,
  }}
  class="container-fluid row"
>
  {#if !reload}
    <div
      class="col s12 row navifation-cont {managementColors.general.colorMode ==
      'dark'
        ? 'navifation-cont-border--dark'
        : managementColors.general.colorMode == 'white'
          ? 'navifation-cont-border--white'
          : ''}"
      use:styling={{
        background: managementColors.general.backgroundColor,
      }}
    >
      <NavigationTop
        bind:languageConection={handleLanguage}
        bind:logoUrl={propertyData.logo}
        bind:propertyName={propertyData.name}
        bind:propertyId={state.propertyId}
        bind:lang={language}
        bind:listLanguagesEnabled={languagesEnabled}
        bind:features={faeturesList}
        bind:backgroundColorView={managementColors.general.backgroundColor}
        bind:colorLetter={managementColors.general.colorLetter}
        bind:colorAlternative={managementColors.general.colorAlternative}
        bind:managementColors
        bind:showBtnBack
        bind:iframeFromLanding={propEmpty}
      />
    </div>
    {#if !showContent}
      <div
        use:styling={{
          background: managementColors.general.backgroundColor,
        }}
        class="col s12 m12 l12 xl12 outlets"
      >
        <div
          class={!outlet.inService && outlet.type != "other"
            ? "col s12 preview__img off__content"
            : "col s12 preview__img"}
        >
          {#if outlet.coverIcon}
            <img
              src={outlet.cover}
              class="preview__img__photo {getColorComponentsAndIcons(
                managementColors.color
              ).title}"
              style="width: 200px; text-align: center; "
            />
          {:else}
            <img src={outlet.cover} class="preview__img__photo" />
          {/if}
          {#if outlet.type != "other"}
            <img src={outlet.photo} class="preview__img__logo" />
          {/if}
        </div>

        <div class="col s12 preview__name">
          <span
            class="preview__name__text"
            use:styling={{
              color:
                !outlet.inService && outlet.type != "other"
                  ? managementColors.general.colorOff
                  : managementColors.general.colorLetter,
            }}
          >
            {outlet.name}
          </span>
        </div>

        {#if outlet.type != "other"}
          <div class="col s12 preview__description">
            {#if !outlet.inService}
              <span
                class="preview__description__text"
                use:styling={{
                  color: managementColors.general.colorOff,
                }}
              >
                <span
                  class="off-Oservice"
                  use:styling={{
                    color: managementColors.general.colorOff,
                  }}>{$_("Off hours")}</span
                >
                {#if outlet.timeLeft}
                  {#if outlet.timeLeft.in == "day" && outlet.timeLeft.value === 1}
                    {$_("Available tomorrow at")}
                    {outlet.nextAvailabilities.fromHours} -
                    {outlet.nextAvailabilities.toHours}
                  {:else}
                    {$_("Available in")}
                    {outlet.timeLeft.value}
                    {$_(outlet.timeLeft.in)}
                  {/if}
                {:else}
                  {$_("Temporarily closed")}
                {/if}
              </span>
            {/if}
            {#if outlet.inService}
              <span
                class="preview__description__text"
                use:styling={{
                  color: managementColors.general.colorLetter,
                }}
                >{outlet.currentAvailabilities.fromHours}-{outlet
                  .currentAvailabilities.toHours}</span
              >
            {/if}
          </div>
        {/if}

        <div class="col s12 txt__description">
          <span
            class="preview__description__text"
            use:styling={{
              color:
                !outlet.inService && outlet.type != "other"
                  ? managementColors.general.colorOff
                  : managementColors.general.colorLetter,
            }}
          >
            {@html outlet.description}
          </span>
        </div>

        <ul class="col s12 preview__list">
          {#each outlet.contents as content, i}
            <li
              class="preview__list__item"
              use:styling={{
                background: managementColors.general.backgroundColorCards,
              }}
              on:click={handleOutletMenuDetail(outlet, content)}
            >
              <div
                class={!content.inService && outlet.type != "other"
                  ? "off__list__item__content"
                  : "preview__list__item__content"}
                use:styling={{
                  color: managementColors.general.colorLetter,
                }}
              >
                <div
                  class={!content.inService && outlet.type != "other"
                    ? "off__title__menu"
                    : "content__title__menu"}
                  use:styling={{
                    color:
                      !content.inService && outlet.type != "other"
                        ? managementColors.general.colorOff
                        : managementColors.general.colorLetter,
                  }}
                >
                  <span
                    class="txt__title__menu"
                    use:styling={{
                      color:
                        !content.inService && outlet.type != "other"
                          ? managementColors.general.colorOff
                          : managementColors.general.colorLetter,
                    }}>{content.title}</span
                  >
                </div>
                {#if !content.inService && content.timeLeft && outlet.type != "other"}
                  <div class="content__description__menu">
                    <i
                      class="material-icons icon-time"
                      use:styling={{
                        color: !content.inService
                          ? managementColors.general.colorOff
                          : managementColors.general.colorLetter,
                      }}>schedule</i
                    >
                    <span
                      class="txt__menu__timeLeft"
                      use:styling={{
                        color: !content.inService
                          ? managementColors.general.colorOff
                          : managementColors.general.colorLetter,
                      }}
                    >
                      {#if content.timeLeft.in == "day" && content.timeLeft.value === 1}
                        {$_("Available tomorrow at")}
                        {content.nextAvailabilities.fromHours} -
                        {content.nextAvailabilities.toHours}
                      {:else}
                        {$_("Available in")}
                        {content.timeLeft.value}
                        {$_(content.timeLeft.in)}
                      {/if}
                    </span>
                  </div>
                {/if}
              </div>

              {#if content.preview}
                <div
                  class={!content.inService && outlet.type != "other"
                    ? "off__cont__list__img"
                    : "preview__list__item__img"}
                >
                  <img
                    src={content.preview}
                    class={!content.inService && outlet.type != "other"
                      ? "off__content__img preview__list__item__img__photo"
                      : "preview__list__item__img__photo"}
                  />
                </div>
              {/if}
            </li>
          {/each}
        </ul>
        {#if openModalOffMenu}
          <div id="modal-desktop" class="modal__base-hide modal__base">
            <div
              id="card-modal-desktop"
              class="modal"
              use:styling={{
                background: managementColors.general.backgroundColor,
              }}
            >
              <div class="modal__cont col s12">
                <div class="icon__time__modal">
                  <div class="back__color__icon">
                    <i class="material-icons schedule__icon">schedule</i>
                  </div>
                </div>
                {#if openModalOffMenu.content.timeLeft}
                  <div class="cont__title">
                    <span
                      class="text__title"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                    >
                      {#if openModalOffMenu.content.timeLeft.in == "day" && openModalOffMenu.content.timeLeft.value === 1}
                        {$_("Available tomorrow at")}
                        {openModalOffMenu.content.nextAvailabilities.fromHours} -
                        {openModalOffMenu.content.nextAvailabilities.toHours}
                      {:else}
                        {$_("Available in")}
                        {openModalOffMenu.content.timeLeft.value}
                        {$_(openModalOffMenu.content.timeLeft.in)}
                      {/if}
                    </span>
                  </div>
                {/if}

                <div class="cont__message">
                  <span
                    class="text_-message"
                    use:styling={{
                      color: managementColors.general.colorLetter,
                    }}
                  >
                    {$_("This menu is not available for ordering at this time")}
                  </span>
                </div>

                <div class="cont__buttons">
                  <button
                    class="btn btn-success row button--return"
                    on:click={() => (openModalOffMenu = false)}
                  >
                    <span
                      class="text--return"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                    >
                      {$_("Return")}
                    </span>
                  </button>

                  <button
                    class="btn btn-success row button--delete"
                    on:click={goToMenu}
                    use:styling={{
                      background: managementColors.general.colorLetter,
                    }}
                  >
                    <span
                      class="text--delete"
                      use:styling={{
                        color: managementColors.general.backgroundColor,
                      }}
                    >
                      {$_("View Products")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        {/if}
      </div>
    {:else if fileUrl && !contentUrl}
      <FileViewer bind:contentUrl={fileUrl} />
    {:else if contentUrl && !fileUrl}
      <LinkViewer bind:contentUrl />
    {/if}
  {/if}

  {#if reload}
    <div
      use:styling={{
        background:
          managementColors &&
          managementColors.general &&
          managementColors.general.backgroundColor,
      }}
      class="reloading row col s12"
    >
      <Reloading bind:managementColors />
    </div>
  {/if}
</div>

<style>
  .reloading {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .container-fluid {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .view-description__cont-image {
    width: 100%;
  }

  .preview__img {
    position: relative;
    display: flex;
    justify-content: center;
    /*height: 370px;*/
    padding: 0px !important;
  }

  .preview__img__photo {
    height: 100%;
    width: 100%;
  }

  .preview__img__logo {
    position: absolute;
    bottom: -34px;
    width: 20%;
    height: 35%;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
  }

  .filter__img {
    filter: invert(55%) sepia(24%) saturate(6502%) hue-rotate(133deg)
      brightness(100%) contrast(80%);
  }

  .preview__name {
    padding: 48px 0px 0px 0px !important;
    word-break: break-word;
  }

  .preview__name__text {
    color: #212121;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
  }

  .preview__description {
    word-break: break-word;
    padding: 0px !important;
  }

  .preview__description__text {
    color: #212121;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
  }

  .preview__list {
    padding: 10px 0px 0px 0px !important;
    display: grid;
  }

  .preview__list__item {
    display: grid;
    height: 52px;
    margin-bottom: 8px;
    box-shadow: 0px 4px 8px #00000014;
    border-radius: 4px;
    cursor: pointer;
  }

  .preview__list__item__img {
    width: 52px;
    height: 52px;
    border-radius: 4px 0px 0px 4px;
    opacity: 1;
    display: inline-block;
    justify-self: end;
    position: relative;
    top: -52px;
  }

  .preview__list__item__content {
    font-weight: 600;
    height: 52px;
    flex-grow: 100;
    opacity: 1;
    display: inline-block;
    width: 84%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    padding: 15px 0px 0px 15px !important;
  }

  .preview__list__item__img__photo {
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 0px 4px 4px 0px;
  }

  .outlets {
    padding: 0px 230px 0px 230px !important;
    top: 82px;
  }

  .txt__description {
    word-break: break-word;
    padding: 8px 0px 0px 0px !important;
  }

  @media only screen and (min-width: 20px) {
    .outlets {
      padding: 0px 0px 800px 0px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }
    .preview__img {
      /*height: 300px;*/
      margin-top: 67px;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(1, 1fr);
    }

    .preview__description__text {
      font-size: 14px;
    }
    .preview__name__text {
      font-size: 20px;
    }

    .preview__list__item__content {
      font-size: 14px;
    }
    .preview__img__logo {
      width: 102px;
      height: 102px;
    }

    .preview__img__photo {
      border-radius: none !important;
    }

    .preview__list {
      padding: 10px 12px 0px 12px !important;
    }

    .preview__name {
      padding: 48px 12px 0px 12px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 12px 0px 12px !important;
    }

    .preview__description {
      padding: 0px 12px 0px 12px !important;
    }
  }

  @media only screen and (min-width: 700px) {
    .outlets {
      padding: 92px 32px 1100px 32px !important;
    }

    .preview__img {
      /*height: 370px;*/
      margin-top: 0;
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }

    .preview__description__text {
      font-size: 16px;
    }
    .preview__name__text {
      font-size: 24px;
    }

    .preview__list__item__content {
      font-size: 14px;
    }
    .preview__img__logo {
      width: 148px;
      height: 148px;
    }

    .preview__list {
      padding: 10px 0px 0px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 5px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }
  }
  @media only screen and (min-width: 1200px) {
    .outlets {
      padding: 123px 230px 950px 230px !important;
    }

    .preview__img {
      /*height: 400px;*/
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }
    .preview__list {
      padding: 10px 0px 200px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 0px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }

    .preview__img__logo {
      width: 190px;
      height: 190px;
    }
  }

  @media only screen and (min-width: 1420px) {
    .outlets {
      padding: 95px 320px 950px 320px !important;
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }
    .preview__list {
      padding: 10px 0px 200px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 1550px) {
    .outlets {
      padding: 95px 350px 950px 350px !important;
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__img {
      /*height: 450px;*/
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }
    .preview__list {
      padding: 10px 0px 200px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }

    .preview__img__logo {
      width: 204px;
      height: 204px;
    }
  }

  @media only screen and (min-width: 1800px) {
    .outlets {
      padding: 142px 450px 1000px 450px !important;
    }

    .preview__img__photo {
      border-radius: 20px !important;
    }

    .preview__list__item {
      width: 100% !important;
    }

    .preview__list {
      column-gap: 1em !important;
      grid-template-columns: repeat(2, 1fr);
    }
    .preview__list {
      padding: 10px 0px 200px 0px !important;
    }

    .preview__name {
      padding: 48px 0px 0px 0px !important;
    }

    .preview__description {
      padding: 0px 0px 0px 0px !important;
    }

    .txt__description {
      word-break: break-word;
      padding: 8px 0px 0px 0px !important;
    }
  }

  .off-Oservice {
    background: #e3e3e3;
    padding: 3px 5px 1px 0px;
    border-radius: 4px;
    font-weight: 700;
    padding-left: 5px;
    text-transform: uppercase;
    padding-right: 5px;
    font-size: 12px;
    color: #212121 !important;
  }

  .off__content {
    opacity: 24%;
  }

  .off__content__txt {
    color: #686c6d !important;
  }

  .off__content__img {
    opacity: 28%;
  }

  .off__list__item__content {
    flex-grow: 100;
    opacity: 1;
    display: block;
    padding: 0px 0px 0px 16px !important;
  }

  .txt__menu__timeLeft {
    font-size: 12px;
    color: #686c6d;
  }

  .txt__title__menu {
    color: #212121;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    width: 81%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  .off__txt__menu {
    color: #686c6d;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    width: 81%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  .content__title__menu {
    margin-top: 0px;
  }

  .off__title__menu {
    margin-top: 6px;
  }

  .content__description__menu {
    display: flex;
    margin-top: -3px;
  }

  .off__cont__list__img {
    width: 52px;
    height: 52px;
    border-radius: 4px 0px 0px 4px;
    opacity: 1;
    display: inline-block;
    justify-self: end;
    position: relative;
    top: -50px;
  }
  .icon-time {
    margin-right: 6px;
    font-size: 16px;
    color: #686c6d;
  }

  .modal__base-hide {
    display: none; /* Hidden by default */
  }

  .modal__base {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Stay in place */
    z-index: 105; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  .modal {
    display: block !important;
    z-index: 106 !important;
    height: auto;
    transform: scaleX(1) scaleY(1);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    padding-bottom: 32px;
    opacity: 1;
    margin: 0 auto;
  }

  .modal__cont {
    padding: 0px;
    height: 90%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .modal__cont::-webkit-scrollbar {
    display: none;
  }

  .cont__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 32px 40px 0px 40px;
    text-align: left;
  }

  .cont__message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 14px 40px 0px 40px;
    text-align: left;
  }

  .text_-message {
    font-size: 1rem;
  }

  .text__title {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .cont__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 10px;
  }

  .button--return {
    background: transparent;
    border: none;
    box-shadow: none;
    margin: 0px 20px 0px 20px !important;
  }

  .button--delete {
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    margin: 0px !important;
  }

  .button--delete > .text--delete {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    text-transform: initial;
  }

  .text--return {
    font-size: 16px;
    font-weight: 600;
    text-transform: initial;
  }

  .form__cont {
    height: 90%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background: white;
    border-radius: 20px 20px 0px 0px;
    padding: 0px;
  }

  .form__cont::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 20px) {
    .modal {
      border-radius: 20px 20px 0px 0px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .text--return {
      font-size: 1rem;
    }

    .text--delete {
      font-size: 1rem;
    }

    .icon__time__modal {
      display: flex;
      justify-content: center;
      padding-top: 32px;
    }

    .schedule__icon {
      font-size: 56px !important;
      position: relative;
      top: 8.5px;
      display: flex;
      justify-content: center;
      color: #686c6d;
    }
    .back__color__icon {
      background: #f4f4f4 0% 0% no-repeat padding-box;
      border-radius: 50%;
      width: 72px;
      height: 72px;
    }

    .cont__message {
      justify-content: center;
      padding: 8px 24px 0px 24px;
    }

    .text_-message {
      font-size: 14px;
    }

    .cont__title {
      justify-content: center;
      padding: 16px 24px 0px 24px;
    }

    .text__title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media only screen and (min-width: 1200px) {
    .modal {
      width: 38%;
      border-radius: 12px;
      position: relative;
    }
    .text--return {
      font-size: 1rem;
    }

    .text--delete {
      font-size: 1rem;
    }

    .icon__time__modal {
      display: flex;
      justify-content: center;
      padding-top: 32px;
    }

    .schedule__icon {
      font-size: 56px !important;
      position: relative;
      top: 8.5px;
      display: flex;
      justify-content: center;
      color: #686c6d;
    }
    .back__color__icon {
      background: #f4f4f4 0% 0% no-repeat padding-box;
      border-radius: 50%;
      width: 72px;
      height: 72px;
    }

    .cont__message {
      justify-content: center;
      padding: 8px 24px 0px 24px;
    }

    .text_-message {
      font-size: 14px;
    }

    .cont__title {
      justify-content: center;
      padding: 16px 24px 0px 24px;
    }

    .text__title {
      font-size: 18px;
      font-weight: 600;
    }
  }
</style>

<script>
  import { _, locale } from "svelte-i18n";
  import { pop, location } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import { push } from "svelte-spa-router";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);

  const init = () => {
    callLanguageActive();
  };

  const callLanguageActive = () => {
    if (localStorage.getItem("language")) {

       locale.set((localStorage.getItem("language")));
    } else {
      locale.set(window.navigator.language.split("-")[0]);
    }
  };

  let handleCreateRequest = () => {
    push(`/properties/${state.propertyId}/create-requests`);
  };

  let handleClose = () => {
    push(`/properties/${state.propertyId}/create-requests`);
  };

  init();
</script>

<div class="col s12 message-request__cont row">
  <div class="message-request row col s12">
    <div class="cols 12 header">
      <i class="material-icons icon-close" on:click={handleClose}>close</i>
    </div>
    <div class="cols 12 message-request__body col l8 offset-l2 m12 s12">
      <div class="cols 12 img-cont">
        <img class="img-message-request " src="/img/error_message.svg" alt="" />
      </div>
      <div class="cols 12 message-cont">
        <span class="message-cont__text {$locale}">{$_("We are sorry")}</span>
      </div>

      <div class="cols 12 text-cont">
        <span class="text-cont__text {$locale}"
          >{$_("Your requirement could not be created. Please try again")}</span
        >
      </div>

      <div class="col s12 row message-request__actions--desktop">
        <button
          class="btn btn-success button--continuebase--desktop"
          on:click={handleCreateRequest}
        >
          <span class="text--button {$locale}">{$_("Try again")}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="col s12 row message-request__actions">
    <button
      class="btn btn-success button--continuebase"
      on:click={handleCreateRequest}
    >
      <span class="text--button {$locale}">{$_("Try again")}</span>
    </button>
  </div>
</div>

<style>
  .message-request__cont {
    height: 100%;
    margin: 0px;
  }

  .message-request__header {
    background: transparent;
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-close {
    width: 24px;
    height: 24px;
    float: right;
    font: normal normal normal 24px/29px Material Icons;
    letter-spacing: 0px;
    color: #707070;
    cursor: pointer;
    opacity: 1;
  }

  .img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
  }

  .message-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
    text-align: center;
  }

  .message-cont__text {
    font-size: 2.5rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .text-cont {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-cont__text {
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .img-message-request {
    width: 170px;
    height: 135px;
    opacity: 1;
  }

  .message-request {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 0px 0px;
    height: 80%;
    min-height: 377px;
    opacity: 1;
    position: relative;
    padding: 12px 12px 0px 12px;
    margin-bottom: 0px;
  }

  .header {
    padding-bottom: 17px;
  }

  .message-request__body {
    padding-top: 100px;
  }

  .message-request__actions {
    bottom: 0;
    margin: 0px;
    position: fixed;
    height: 70px;
    display: flex;
    align-items: center;
  }

  .message-request__actions > .button--continuebase {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    cursor: pointer;
    width: 100%;
    background: #2f4050 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .button--block {
    background: #c3c3c3 0% 0% no-repeat padding-box !important;
  }

  .text--button {
    font-size: 0.875rem;
    color: #ffffff;
    font-weight: 700;
    text-transform: initial;
  }

  .loader {
    background: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button--continuebase--desktop {
    display: none;
  }

  .message-request__actions--desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
  }

  @media only screen and (min-width: 601px) {
    .message-request {
      height: auto;
      padding: 32px 32px 32px 32px;
    }

    .message-request__actions {
      padding: 0px 32px 0px 32px;
    }

    .text-cont__text {
      font-size: 1rem;
    }

    .text--button {
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .message-request {
      border-radius: 20px 20px 20px 20px;
      height: auto;
      min-height: 0px;
      padding: 32px 32px 30px 32px;
    }

    .message-request__actions {
      display: none;
    }

    .button--continuebase--desktop {
      display: block;
      width: 276px;
      background: #2f4050 0% 0% no-repeat padding-box;
    }
  }
</style>

<script>
  import Router from "svelte-spa-router";
  import { wrap } from "svelte-spa-router/wrap";

  import {
    addMessages,
    register,
    init,
    getLocaleFromNavigator,
    locale
  } from "svelte-i18n";
  import LandingPage from "./landing-page/list-features/component.svelte";
  import PageNoFound from "./page-no-found/component.svelte";
  import Information from "./property-information/component.svelte";
  import Menus from "./restaurants/list/component.svelte";
  import MenusService from "./services/list/component.svelte";
  import MenuDetail from "./services/detail/component.svelte";
  import PromotionsList from "./promotions/list/component.svelte";
  import PromotionDetail from "./promotions/detail/component.svelte";
  import OutletDetail from "./outlets/detail/component.svelte";
  import OutletComponentDetail from "./outlets/detail/contents-component.svelte";
  import RoomServices from "./room-services/list/component.svelte";
  import CreateRequests from "./requests/create/form.svelte";
  import ExperienceDetail from "./my-experiences/detail/successfully.svelte";
  import ErrorRequest from "./my-experiences/detail/error.svelte";
  import MyExperiences from "./my-experiences/list/component.svelte";
  import RoomServiceDetail from "./room-services/detail/component.svelte";
  import ValidationUser from "./user-validation/login/component.svelte";
  import ValidationMagicLink from "./user-validation/magic-link/component.svelte";
  import MessageNoValidation from "./user-validation/message-novalidation.svelte";
  import MessageSendEmail from "./user-validation/message-send-email.svelte";
  import Thankyoupage from "./communications/thankyoupage/component.svelte";
  import ListItems from "./landing-page/list-items/component.svelte";
  import DetailItem from "./landing-page/detail-item/component.svelte";
  import NoCommentsView from "./components/comments/no-comments.svelte";
  import en from "./assets/en.json";
  import es from "./assets/es.json";
  import PickupGuests from "./pickup/component.svelte";
  import { getCurrentLanguage, setLanguage } from "./language-service";

  const routes = {
    "/": LandingPage,
    "/properties/:propertyId/pickup": PickupGuests,
    "/properties/:propertyId/:reservationId/request/:id/no-comments":
      NoCommentsView,
    "/properties/:propertyId/feature/:featureId/detail-item/:itemName":
      DetailItem,
    "/properties/:propertyId/feature/:featureId/list-items": ListItems,
    "/properties/:propertyId/communications/thank-you-page/:messageId":
      Thankyoupage,
    "/properties/:propertyId/message-send-email": MessageSendEmail,
    "/properties/:propertyId/no-validation": MessageNoValidation,
    "/properties/:propertyId/user-validation": ValidationUser,
    "/properties/:propertyId/magic-link-validation": ValidationMagicLink,
    "/properties/:propertyId/room-services/detail/:menuId": RoomServiceDetail,
    "/properties/:propertyId/my-requests": wrap({
      asyncComponent: () => MyExperiences,
      conditions: [
        (detail) => {
          window.location.href = `/#/properties/${detail.params.propertyId}/my-experiences`;
          return false;
        }
      ]
    }),
    "/properties/:propertyId/:reservationId/completed-request/:id": wrap({
      asyncComponent: () => ExperienceDetail,
      conditions: [
        (detail) => {
          console.log(detail);
          window.location.href = `/#/properties/${detail.params.propertyId}/my-experience/${detail.params.id}`;
          return false;
        }
      ]
    }),
    "/properties/:propertyId/my-experiences": MyExperiences,
    "/properties/:propertyId/my-experience/:id": ExperienceDetail,
    "/properties/:propertyId/error-request": ErrorRequest,
    "/properties/:propertyId/create-requests": CreateRequests,
    "/properties/:propertyId/room-services": RoomServices,
    "/properties/:propertyId/room-service/:idOutlet": OutletDetail,
    "/properties/:propertyId/room-service/:idOutlet/:idContent":
      OutletComponentDetail,
    "/properties/:propertyId/services": MenusService,
    "/properties/:propertyId/service/:idOutlet": OutletDetail,
    "/properties/:propertyId/service/:idOutlet/:idContent":
      OutletComponentDetail,
    "/properties/:propertyId/other/:idOutlet": OutletDetail,
    "/properties/:propertyId/other/:idOutlet/:idContent": OutletComponentDetail,
    "/properties/:propertyId/promotions": PromotionsList,
    "/properties/:propertyId/promotion/:idOutlet": PromotionDetail,
    "/properties/:propertyId/section/promotion/:idOutlet": PromotionDetail,
    "/properties/:propertyId/restaurants": Menus,
    "/properties/:propertyId/restaurant/:idOutlet": OutletDetail,
    "/properties/:propertyId/restaurant/:idOutlet/:idContent":
      OutletComponentDetail,
    "/properties/:propertyId/menus/detail/:menuId": MenuDetail,
    "/properties/:propertyId/information": Information,
    "/properties/:propertyId/reservations/:reservationId": LandingPage,
    "/properties/:propertyId": LandingPage,
    "*": PageNoFound,
    "/properties/": PageNoFound
  };
  const urlParams = new URLSearchParams(new URLSearchParams(window.location.href.split("#/")[1]));
  const queryLang = urlParams.get("language");
  if (queryLang) {
    setLanguage(queryLang).then(getCurrentLanguage).then(configureI18N);
  } else {
    getCurrentLanguage().then(configureI18N);
  }

  locale.subscribe(setLanguage);

  window.addEventListener("offline", function (event) {});
  window.addEventListener("online", function (event) {
    location.reload();
  });

  function configureI18N(language) {
    addMessages("en", en);
    addMessages("es", es);

    init({
      fallbackLocale: "en",
      initialLocale: language
    });
  }
</script>

<main>
  <Router {routes} />
</main>

<style>
  :global(.redfilter) {
    filter: invert(10%) sepia(94%) saturate(6229%) hue-rotate(353deg)
      brightness(114%) contrast(98%);
    width: 28px;
    height: 28px;
  }

  :global(.orangefilter) {
    filter: invert(77%) sepia(23%) saturate(6787%) hue-rotate(341deg)
      brightness(101%) contrast(98%);
    width: 28px;
    height: 28px;
  }

  :global(.yellowfilter) {
    filter: invert(91%) sepia(30%) saturate(3593%) hue-rotate(337deg)
      brightness(101%) contrast(102%);
    width: 28px;
    height: 28px;
  }

  :global(.bluefilter) {
    filter: invert(14%) sepia(100%) saturate(7417%) hue-rotate(243deg)
      brightness(93%) contrast(117%);
    width: 28px;
    height: 28px;
  }

  :global(.purplefilter) {
    filter: invert(30%) sepia(77%) saturate(5486%) hue-rotate(287deg)
      brightness(114%) contrast(102%);
    width: 28px;
    height: 28px;
  }

  :global(.greyfilter) {
    filter: invert(63%) sepia(2%) saturate(33%) hue-rotate(314deg)
      brightness(93%) contrast(96%);
    width: 28px;
    height: 28px;
  }

  :global(.redwinefilter) {
    filter: invert(17%) sepia(79%) saturate(2679%) hue-rotate(353deg)
      brightness(92%) contrast(99%);
    width: 28px;
    height: 28px;
  }

  :global(.goldfilter) {
    filter: invert(63%) sepia(35%) saturate(907%) hue-rotate(347deg)
      brightness(95%) contrast(89%);
    width: 28px;
    height: 28px;
  }

  :global(.mustardfilter) {
    filter: invert(62%) sepia(90%) saturate(385%) hue-rotate(2deg)
      brightness(104%) contrast(93%);
    width: 28px;
    height: 28px;
  }

  :global(.gcreamfilter) {
    filter: invert(55%) sepia(24%) saturate(6502%) hue-rotate(133deg)
      brightness(100%) contrast(80%);
    width: 28px;
    height: 28px;
  }

  :global(.blightfilter) {
    filter: invert(38%) sepia(99%) saturate(576%) hue-rotate(185deg)
      brightness(90%) contrast(90%);
    width: 28px;
    height: 28px;
  }

  :global(.darkvioletfilter) {
    filter: invert(37%) sepia(7%) saturate(5754%) hue-rotate(218deg)
      brightness(85%) contrast(83%);
    width: 28px;
    height: 28px;
  }

  :global(.dpurplefilter) {
    filter: invert(34%) sepia(4%) saturate(7455%) hue-rotate(281deg)
      brightness(108%) contrast(76%);
    width: 28px;
    height: 28px;
  }

  :global(.greenfilter) {
    filter: invert(58%) sepia(21%) saturate(5200%) hue-rotate(74deg)
      brightness(107%) contrast(45%);
    width: 28px;
    height: 28px;
  }

  :global(.violetfilter) {
    filter: invert(22%) sepia(93%) saturate(2715%) hue-rotate(264deg)
      brightness(93%) contrast(113%);
    width: 28px;
    height: 28px;
  }

  :global(.gdarkfilter) {
    filter: invert(23%) sepia(4%) saturate(24%) hue-rotate(360deg)
      brightness(98%) contrast(92%);
    width: 28px;
    height: 28px;
  }

  :global(.cdarkfilter) {
    filter: invert(14%) sepia(30%) saturate(3389%) hue-rotate(341deg)
      brightness(95%) contrast(111%);
    width: 28px;
    height: 28px;
  }

  :global(.clightfilter) {
    filter: invert(32%) sepia(95%) saturate(509%) hue-rotate(348deg)
      brightness(107%) contrast(92%);
    width: 28px;
    height: 28px;
  }

  :global(.ydarkfilter) {
    filter: invert(54%) sepia(77%) saturate(463%) hue-rotate(4deg)
      brightness(92%) contrast(88%);
    width: 28px;
    height: 28px;
  }

  :global(.greendarkfilter) {
    filter: invert(23%) sepia(11%) saturate(4934%) hue-rotate(63deg)
      brightness(88%) contrast(85%);
    width: 28px;
    height: 28px;
  }

  :global(.bluetwofilter) {
    filter: invert(22%) sepia(84%) saturate(638%) hue-rotate(181deg)
      brightness(98%) contrast(101%);
    width: 28px;
    height: 28px;
  }

  :global(.bluedarkfilter) {
    filter: invert(5%) sepia(69%) saturate(7317%) hue-rotate(257deg)
      brightness(70%) contrast(94%);
    width: 28px;
    height: 28px;
  }

  :global(.purpledarkfilter) {
    filter: invert(9%) sepia(95%) saturate(1466%) hue-rotate(301deg)
      brightness(94%) contrast(99%);
    width: 28px;
    height: 28px;
  }

  :global(.blackcolor) {
    filter: invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg)
      brightness(102%) contrast(102%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthredfilter) {
    filter: invert(92%) sepia(17%) saturate(2329%) hue-rotate(297deg)
      brightness(106%) contrast(87%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthorangefilter) {
    filter: invert(99%) sepia(91%) saturate(4531%) hue-rotate(304deg)
      brightness(106%) contrast(105%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthyellowfilter) {
    filter: invert(86%) sepia(5%) saturate(2043%) hue-rotate(339deg)
      brightness(112%) contrast(106%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthbluefilter) {
    filter: invert(82%) sepia(12%) saturate(910%) hue-rotate(190deg)
      brightness(104%) contrast(96%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthgreenfilter) {
    filter: invert(91%) sepia(5%) saturate(1782%) hue-rotate(41deg)
      brightness(94%) contrast(94%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthpurplefilter) {
    filter: invert(91%) sepia(20%) saturate(248%) hue-rotate(293deg)
      brightness(92%) contrast(98%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthgreyfilter) {
    filter: invert(100%) sepia(1%) saturate(1452%) hue-rotate(275deg)
      brightness(109%) contrast(78%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthredwinefilter) {
    filter: invert(62%) sepia(19%) saturate(5367%) hue-rotate(321deg)
      brightness(99%) contrast(77%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthgoldfilter) {
    filter: invert(25%) sepia(52%) saturate(4260%) hue-rotate(342deg)
      brightness(88%) contrast(103%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthmustardfilter) {
    filter: invert(80%) sepia(62%) saturate(688%) hue-rotate(313deg)
      brightness(108%) contrast(94%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthgcreamfilter) {
    filter: invert(88%) sepia(18%) saturate(1421%) hue-rotate(336deg)
      brightness(103%) contrast(104%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthblightfilter) {
    filter: invert(66%) sepia(10%) saturate(2198%) hue-rotate(57deg)
      brightness(89%) contrast(82%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthdarkvioletfilter) {
    filter: invert(74%) sepia(48%) saturate(2992%) hue-rotate(192deg)
      brightness(117%) contrast(91%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthdpurplefilter) {
    filter: invert(83%) sepia(41%) saturate(2186%) hue-rotate(195deg)
      brightness(96%) contrast(101%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthvioletfilter) {
    filter: invert(82%) sepia(16%) saturate(785%) hue-rotate(206deg)
      brightness(103%) contrast(102%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthgdarkfilter) {
    filter: invert(98%) sepia(84%) saturate(3258%) hue-rotate(281deg)
      brightness(98%) contrast(105%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthcdarkfilter) {
    filter: invert(69%) sepia(0%) saturate(2478%) hue-rotate(345deg)
      brightness(86%) contrast(90%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthclightfilter) {
    filter: invert(55%) sepia(78%) saturate(2560%) hue-rotate(1deg)
      brightness(103%) contrast(105%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthydarkfilter) {
    filter: invert(88%) sepia(20%) saturate(4656%) hue-rotate(345deg)
      brightness(101%) contrast(105%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthgreendarkfilter) {
    filter: invert(52%) sepia(99%) saturate(349%) hue-rotate(118deg)
      brightness(91%) contrast(91%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthbluetwofilter) {
    filter: invert(56%) sepia(13%) saturate(5877%) hue-rotate(195deg)
      brightness(101%) contrast(101%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthbluedarkfilter) {
    filter: invert(69%) sepia(73%) saturate(6119%) hue-rotate(240deg)
      brightness(99%) contrast(104%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthpurpledarkfilter) {
    filter: invert(55%) sepia(99%) saturate(3519%) hue-rotate(304deg)
      brightness(102%) contrast(101%);
    width: 28px;
    height: 28px;
  }

  :global(.ligthblackcolor) {
    filter: invert(61%) sepia(10%) saturate(6872%) hue-rotate(11deg)
      brightness(95%) contrast(76%);
    width: 28px;
    height: 28px;
  }
</style>

<script>
  import { _, locale } from "svelte-i18n";
  import { push } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import RoutesServices from "../../management-routes-service";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import CardOutlet from "./card-component.svelte";
  import LandingPage from "../../landing-page/list-features/component.svelte";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let languagesEnabled = [];
  let language;
  let faeturesList = [];
  let managementColors = {
    color: "#2bbbad",
    general: { backgroundColor: "#ffffff" },
  };
  let showBtnBack = true;
  let auth = JSON.parse(localStorage.getItem("auth"))
    ? JSON.parse(localStorage.getItem("auth"))
    : null;
  let propEmpty = "";
  let reload = true;
  let outlets = [];
  let dataHeader;
  let landing;
  let datalogin = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : null;

  let onFinishedLoading = () => {
    landing = true;

    const validationColor = () => {
      JSON.parse(localStorage.getItem("managementColors"))
        ? (managementColors = JSON.parse(
            localStorage.getItem("managementColors"),
          ))
        : (managementColors.color = "#2bbbad");
    };

    validationColor();

    const init = (lang) => {
      setTimeout(() => {
        Promise.all([
          PropertyFeatures.list({ propertyId: state.propertyId }, lang),
          PropertyFeatures.proppertyInformation({
            propertyId: state.propertyId,
          }),
          PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
          PropertyFeatures.outletsLists({
            propertyId: state.propertyId,
            outleType: "promotions",
            language: lang,
            reservationId:
              datalogin && datalogin.reservationId
                ? datalogin.reservationId
                : undefined,
          }),
        ]).then(
          ([features, propertyInformation, guestappsettins, outletsList]) => {
            if (outletsList.length == 0) {
              localStorage.removeItem("amountPromotions");
              push(`/properties/${state.propertyId}`);
            } else if (outletsList.length == 1) {
              localStorage.setItem(
                "amountPromotions",
                JSON.stringify(outletsList.length),
              );
              push(
                `/properties/${state.propertyId}/${outletsList[0].type}/${outletsList[0].id}`,
              );
            } else {
              propertyData = propertyInformation;
              outlets = outletsList;
              dataHeader = JSON.parse(localStorage.getItem("dataHeader"));

              dataHeader.nameFeature = features.find(
                (fet) => fet.codename == "promotions.list",
              ).caption;
              dataHeader.imgFeature = features.find(
                (fet) => fet.codename == "promotions.list",
              ).imgUrl;
              localStorage.setItem("dataHeader", JSON.stringify(dataHeader));
              localStorage.setItem("lastRoute", '"viewlanding"');

              faeturesList = features.filter(
                (fet) => fet.enabled || fet.codename == "property.details",
              );

              validationLanguage(
                guestappsettins.languages,
                propertyInformation.language.code,
              );
              setFontFamily(guestappsettins.fontFamily);

              if (features.length > 0) reload = false;
            }
          },
        );
      }, 200);
    };

    init(
      localStorage.getItem("language") ||
        window.navigator.language.split("-")[0],
    );

    const validationLanguage = (enabledLanguges, languageDefault) => {
      languagesEnabled = enabledLanguges;
      language = languageDefault;
    };

    let setFontFamily = (fontFamily) => {
      PropertyFeatures.setFontFamily(fontFamily);
    };

    let setRoute = () => {
      RoutesServices.setLastRoute("viewlanding");
    };

    setRoute();
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleLanguage = (e) => {
    init(e);
  };
</script>

{#if !landing}
  <div style="display:block">
    <LandingPage bind:params page={"home"} bind:onFinishedLoading />
  </div>
{:else}
  <div
    class="row container-fluid"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    {#if reload}
      <div class=" reloading row">
        <Reloading bind:managementColors />
      </div>
    {:else}
      <div
        class="col s12 row cont__body"
        use:styling={{ background: managementColors.general.backgroundColor }}
      >
        <div
          class="col s12 row navifation-cont {managementColors.general
            .colorMode == 'white'
            ? 'navifation-cont-border--white'
            : managementColors.general.colorMode == 'dark'
              ? 'navifation-cont-border--dark'
              : ''}"
          use:styling={{ background: managementColors.general.backgroundColor }}
        >
          <NavigationTop
            bind:languageConection={handleLanguage}
            bind:logoUrl={propertyData.logo}
            bind:propertyName={propertyData.name}
            bind:propertyId={state.propertyId}
            bind:lang={language}
            bind:listLanguagesEnabled={languagesEnabled}
            bind:features={faeturesList}
            bind:backgroundColorView={managementColors.general.backgroundColor}
            bind:colorLetter={managementColors.general.colorLetter}
            bind:managementColors
            bind:showBtnBack
            bind:iframeFromLanding={propEmpty}
          />
        </div>
        <div class="col s12 m12 l8 xl6 service__name">
          <span
            class="service__name__text"
            use:styling={{ color: managementColors.general.colorLetter }}
          >
            {dataHeader.nameFeature}
          </span>
        </div>

        <div
          use:styling={{ background: managementColors.general.backgroundColor }}
          class="col s12 m12 l12 offset-l2 xl12 offset-xl3 outlets"
        >
          {#each outlets as outlet, i}
            <CardOutlet
              bind:outlet
              bind:propertyId={propertyData.id}
              bind:managementColors
            />
          {/each}
        </div>
      </div>
    {/if}
  </div>
{/if}

<style>
  .container-fluid {
    height: 100% !important;
  }

  .cont__body {
    height: auto;
    padding: 0px 0px 100px 0px !important;
    margin: 0px !important;
  }

  .outlets {
    top: 82px;
  }

  .menus {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
    position: relative;
    top: 82px;
  }

  .reloading {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .service__name {
    position: relative;
    top: 82px;
    padding: 2px 32px 20px 32px !important;
  }

  .service__name__text {
    font-size: 20px;
    font-weight: 700;
  }

  @media only screen and (min-width: 0px) and (max-width: 731px) {
    .outlets {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 1em;
      display: grid;
      padding: 86px 10px 0px 10px !important;
    }
    .service__name {
      right: 25px !important;
    }

    .menus {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 1em;
      display: grid;
      padding: 0px 10px 0px 10px !important;
    }
  }

  @media only screen and (min-width: 732px) {
    .outlets {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
      display: grid;
      padding: 86px 32px 0px 32px !important;
    }
    .service__name__text {
      font-size: 24px;
    }
  }

  @media only screen and (min-width: 732px) {
    .menus {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
      display: grid;
      padding: 0px 32px 0px 32px !important;
    }
  }

  @media only screen and (min-width: 993px) {
    .outlets {
      margin-left: 0px !important;
      padding: 108px 32px 0px 32px !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .service__name {
      padding: 10px 0px 0px 28px !important;
    }

    .service__name__text {
      font-size: 2rem;
    }
  }

  @media only screen and (min-width: 1201px) {
    .service__name {
      padding: 31px 0px 0px 227px !important;
    }

    .service__name__text {
      font-size: 32px;
    }
    .outlets {
      padding: 108px 230px 0px 230px !important;
    }

    .menus {
      padding: 0px 230px 0px 230px !important;
    }
  }

  @media only screen and (min-width: 1451px) {
    .service__name {
      padding: 31px 0px 0px 322px !important;
    }
    .outlets {
      padding: 108px 325px 0px 325px !important;
    }

    .menus {
      padding: 0px 325px 0px 325px !important;
    }
  }
</style>

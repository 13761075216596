<script>
  import { _, locale } from "svelte-i18n";
  import { pop, location } from "svelte-spa-router";
  import { replace } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import CardRequest from "./card-component.svelte";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import Reservations from "../../reservations-service";
  import RoutesServices from "../../management-routes-service";
  import { push } from "svelte-spa-router";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let language;
  let languagesEnabled = [];
  let requestsList = [];
  let reload = true;
  let faeturesList = [];
  let managementColors;
  let showBtnBack = true;
  let reservationId = JSON.parse(localStorage.getItem("auth"))
    ? JSON.parse(localStorage.getItem("auth")).reservationId
    : null;
  let browserIdentify = JSON.parse(localStorage.getItem("browser-identify"));
  let propEmpty = "";
  let dataHeader = JSON.parse(localStorage.getItem("dataHeader"));

  const validationColors = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");
  };

  validationColors();

  const init = (lang) => {
    Promise.all([
      PropertyFeatures.list({ propertyId: state.propertyId }, lang),
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
      PropertyFeatures.requestList({
        propertyId: state.propertyId,
        reservationId: reservationId,
        browserIdentify,
      }),
    ]).then(([features, propertyInformation, myRequests]) => {
      propertyData = propertyInformation;
      requestsList = myRequests;

      requestsList.length > 0 ? (reload = false) : null;
      faeturesList = features.filter(
        (fet) => fet.enabled || fet.codename == "property.details"
      );

      dataHeader.nameFeature = propertyData.name;
      dataHeader.imgFeature = propertyData.logo;
      localStorage.setItem("dataHeader", JSON.stringify(dataHeader));

      validationLanguage(
        JSON.parse(localStorage.getItem("languagesConfig"))
          ? JSON.parse(localStorage.getItem("languagesConfig"))
          : [],
        propertyInformation.language.code
      );
      localStorage.setItem("lastRoute", '"viewlanding"');
    });
  };

  init(
    localStorage.getItem("language") ||
      window.navigator.language.split("-")[0]
  );

  const validationLanguage = (enabledLanguges, languageDefault) => {
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleLanguage = (e) => {
    init(e);
  };

  let outPutUpdateData = (e) => {
    init(
      localStorage.getItem("language") ||
        window.navigator.language.split("-")[0]
    );
  };
</script>

{#if reload}
  <div
    class="container-fluid reloading row"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    <Reloading bind:managementColors />
  </div>
{:else}
  <div
    class="col s12 my-requests__cont row"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    <div
      class="col s12 row navifation-cont {managementColors.general.colorMode ==
      'white'
        ? 'navifation-cont-border--white'
        : managementColors.general.colorMode == 'dark'
          ? 'navifation-cont-border--dark'
          : ''}"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      <NavigationTop
        bind:languageConection={handleLanguage}
        bind:outPutUpdateData
        bind:logoUrl={propertyData.logo}
        bind:propertyName={propertyData.name}
        bind:propertyId={propertyData.id}
        bind:listLanguagesEnabled={languagesEnabled}
        bind:features={faeturesList}
        bind:backgroundColorView={managementColors.general.backgroundColor}
        bind:colorLetter={managementColors.general.colorLetter}
        bind:managementColors
        bind:showBtnBack
        bind:iframeFromLanding={propEmpty}
      />
    </div>
    <div
      class="my-requests row col s12"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      <div class="col s12 my-requests__body row col l12 m12 s12">
        <div class="col s12 header">
          <span
            class="header__text {$locale}"
            use:styling={{ color: managementColors.general.colorLetter }}
          >
            {$_("My experiences")}
          </span>
        </div>
        <div class="row col s12 nopadding">
          {#each requestsList as request, i}
            <CardRequest
              bind:dataRequest={request}
              bind:propertyId={propertyData.id}
              bind:managementColors
            />
          {/each}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .nopadding {
    padding: 0 !important;
  }
  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .my-requests__cont {
    height: 100%;
    margin: 0px;
  }

  .my-requests__header {
    background: transparent;
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    padding: 60px 0px 24px 0px !important;
  }

  .header__text {
    font-size: 1.125rem;
    color: #212121;
    font-weight: 700;
  }

  .my-requests {
    border-radius: 20px 20px 0px 0px;
    height: auto;
    min-height: 377px;
    position: relative;
    top: 60px;
    padding: 12px 12px 0px 12px;
    margin-bottom: 0px;
    opacity: 1;
  }

  .my-requests__body {
    padding: 0px !important;
  }

  .my-requests-cont {
    margin: 0px;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0px;
  }

  .reloading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 703px) {
    .my-requests {
      height: auto;
      padding: 0px 32px 0px 32px !important;
    }

    .header__text {
      font-size: 2rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .my-requests {
      height: auto;
      min-height: 0px;
      padding: 32px 32px 30px 32px;
    }
  }

  @media only screen and (min-width: 1201px) {
    .my-requests {
      height: auto;
      min-height: 0px;
      padding: 0px 230px 0px 230px !important;
    }
  }

  @media only screen and (min-width: 1600px) {
    .my-requests {
      height: auto;
      min-height: 0px;
      padding: 0px 350px 0px 350px !important;
    }
  }
</style>

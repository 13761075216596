import environments from "./environments";
import { locale } from "svelte-i18n";
import { getCurrentLanguage, setLanguage } from "./language-service";

const isLogIn = (propertyId) =>
  localStorage.getItem("auth")
    ? loginMagicLink(propertyId, { code: JSON.parse(localStorage.getItem("auth")).code })
    : Promise.reject({ code: 401 });

const logOut = (propertyId) =>
  localStorage.removeItem("auth") ||
  localStorage.removeItem("reservation")
  || Promise.resolve();

const updateLanguage = (propertyId, data) =>
  fetch(`${environments.reservations.api}/properties/${propertyId}/guest`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "language": data.language,
    },
  }).then((response) => (response ? true : Promise.reject(false)));

const loginMagicLink = (propertyId, data) =>
  fetch(`${environments.reservations.api}/properties/${propertyId}/guest`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "guesthub-context": JSON.stringify({
        properties: [propertyId],
      }),
      "language": localStorage.getItem("language") || 'en'
    },
  }).then((response) => (response ? response.json() : Promise.reject(response)))
    .then(response => {
      localStorage.setItem("auth", JSON.stringify(Object.assign({}, response, {
        propertyId: propertyId,
        code: data.code
      })));
      if (response.browserIdentify) {
        localStorage.setItem("browser-identify", response.browserIdentify);
      }
      getCurrentLanguage().then(currentLanguage => {
        if (!currentLanguage && response.language) {
          setLanguage(response.language).then(() => locale.set(currentLanguage))
        }
      })
      localStorage.setItem(
        "reservation",
        JSON.stringify(new Date().getTime())
      );
      return response;
    }).catch(logOut);

const login = (propertyId, data) =>
  fetch(`${environments.reservations.api}/properties/${propertyId}/guest`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "guesthub-context": JSON.stringify({
        properties: [propertyId],
      }),
      "language": localStorage.getItem("language") || 'en'
    },
  }).then((response) => (response ? response.json() : null))
    .then(response => {
      localStorage.setItem("auth", JSON.stringify(Object.assign({}, response, {
        propertyId: propertyId,
        code: data.code
      })));
      if (response.browserIdentify) {
        localStorage.setItem("browser-identify", response.browserIdentify);
      }
      getCurrentLanguage().then(currentLanguage => {
        if (!currentLanguage && response.language) {
          setLanguage(response.language).then(() => locale.set(currentLanguage))
        }
      })

      localStorage.setItem(
        "reservation",
        JSON.stringify(new Date().getTime())
      );
      return response;
    });

const sendCodeEmail = (propertyId, data) =>
  fetch(
    `${environments.reservations.api}/properties/${propertyId}/guest/send`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "guesthub-context": JSON.stringify({
          properties: [propertyId],
        }),
        "language": localStorage.getItem("language") || 'en'
      },
    }
  ).then((response) => (response.ok ? true : Promise.reject(response)));

export default {
  isLogIn,
  logOut,
  login,
  loginMagicLink,
  sendCodeEmail,
  updateLanguage
};

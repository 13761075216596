<script>
  import { _, locale } from "svelte-i18n";
  import { push } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import CardRoomService from "./card-component.svelte";
  import RoutesServices from "../../management-routes-service";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import CardOutlet from "../../outlets/list/card-component.svelte";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let languagesEnabled = [];
  let language;
  let faeturesList = [];
  let managementColors;
  let showBtnBack = true;
  let propEmpty = "";
  let reload = true;
  let outlets = [];
  let dataHeader = JSON.parse(localStorage.getItem("dataHeader"));

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");
  };

  validationColor();

  let init = (lang) => {
    Promise.all([
      PropertyFeatures.list({ propertyId: state.propertyId }, lang),
      PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
      PropertyFeatures.outletsLists({
        propertyId: state.propertyId,
        outleType: "room-services",
        language: lang,
      }),
    ]).then(
      ([
        features,
        guestappsettins,
        propertyInformation,
        outletsList,
      ]) => {
        if (outletsList.length == 0) {
          localStorage.removeItem("amountMenusRoomService");
          push(`/properties/${state.propertyId}`);
        } else if (outletsList.length == 1) {
          localStorage.setItem(
            "amountMenusRoomService",
            JSON.stringify(outletsList.length)
          );
          if (outletsList.length == 1) {
            push(
              `/properties/${state.propertyId}/${outletsList[0].type}/${outletsList[0].id}`
            );
          }
        } else {
          propertyData = propertyInformation;
          outlets = outletsList;

          dataHeader.nameFeature = features.find(
            (fet) => fet.codename == "room-service.list"
          ).caption;
          dataHeader.imgFeature = features.find(
            (fet) => fet.codename == "room-service.list"
          ).imgUrl;
          localStorage.setItem("dataHeader", JSON.stringify(dataHeader));
          localStorage.setItem("lastRoute", '"viewlanding"');

          faeturesList = features.filter(
            (fet) => fet.enabled || fet.codename == "property.details"
          );

          validationLanguage(
            guestappsettins.languages,
            propertyInformation.language.code
          );
          setFontFamily(guestappsettins.fontFamily);
          if (features.length > 0) reload = false;
        }
      }
    );
  };

  init(
    localStorage.getItem("language") ||
      window.navigator.language.split("-")[0]
  );

  const validationLanguage = (enabledLanguges, languageDefault) => {
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  let setRoute = () => {
    RoutesServices.setLastRoute("viewlanding");
  };

  let handleLanguage = (e) => {
    init(e);
  };

  setRoute();
</script>

<div
  class="col s12 row"
  id="headerid"
  use:styling={{ background: managementColors.general.backgroundColor }}
>
  {#if reload}
    <div class="container-fluid reloading row">
      <Reloading bind:managementColors />
    </div>
  {:else}
    <div
      class="col s12 row cont__body"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      <div
        class="col s12 row navifation-cont {managementColors.general
          .colorMode == 'white'
          ? 'navifation-cont-border--white'
          : managementColors.general.colorMode == 'dark'
          ? 'navifation-cont-border--dark'
          : ''}"
        use:styling={{ background: managementColors.general.backgroundColor }}
      >
        <NavigationTop
          bind:languageConection={handleLanguage}
          bind:logoUrl={propertyData.logo}
          bind:propertyName={propertyData.name}
          bind:propertyId={state.propertyId}
          bind:lang={language}
          bind:listLanguagesEnabled={languagesEnabled}
          bind:features={faeturesList}
          bind:backgroundColorView={managementColors.general.backgroundColor}
          bind:colorLetter={managementColors.general.colorLetter}
          bind:managementColors
          bind:showBtnBack
          bind:iframeFromLanding={propEmpty}
        />
      </div>

      <div class="col s12 m12 l12 xl12 service__name">
        <span
          class="service__name__text"
          use:styling={{ color: managementColors.general.colorLetter }}
        >
          {dataHeader.nameFeature}
        </span>
      </div>

      <div
        use:styling={{ background: managementColors.general.backgroundColor }}
        class="col s12 m12 l12 xl12 outlets"
      >
        {#each outlets as outlet, i}
          <CardOutlet
            bind:outlet
            bind:propertyId={propertyData.id}
            bind:managementColors
          />
        {/each}
      </div>
    </div>
  {/if}
</div>

<style>
  #headerid {
    height: 100%;
  }

  .cont__body {
    height: auto;
    padding: 0px 0px 100px 0px !important;
  }

  .room-services-menus {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 32px 32px 32px !important;
    position: relative;
    top: 82px;
  }

  .reloading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .service__name {
    position: relative;
    top: 82px;
    padding: 16px 32px 24px 32px !important;
  }

  .service__name__text {
    font-size: 1.25rem;
    font-weight: 700;
  }
  .outlets {
    display: grid;
  }

  @media only screen and (min-width: 0px) and (max-width: 731px) {
    .outlets {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 1em;
      display: grid;
      padding: 86px 10px 0px 10px !important;
    }
    .service__name {
      right: 25px !important;
    }

    .room-services-menus {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 1em;
      display: grid;
      padding: 0px 10px 0px 10px !important;
    }
  }

  @media only screen and (min-width: 732px) {
    .outlets {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
      display: grid;
      padding: 86px 32px 0px 32px !important;
    }
    .service__name__text {
      font-size: 24px;
    }
  }

  @media only screen and (min-width: 732px) {
    .room-services-menus {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
      display: grid;
      padding: 0px 32px 0px 32px !important;
    }
  }

  @media only screen and (min-width: 993px) {
    .outlets {
      margin-left: 0px !important;
      padding: 108px 32px 0px 32px !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .service__name {
      padding: 10px 0px 0px 28px !important;
    }

    .service__name__text {
      font-size: 2rem;
    }
  }

  @media only screen and (min-width: 1201px) {
    .service__name {
      padding: 31px 0px 0px 227px !important;
    }

    .service__name__text {
      font-size: 32px;
    }
    .outlets {
      padding: 108px 230px 0px 230px !important;
    }

    .room-services-menus {
      padding: 0px 230px 0px 230px !important;
    }
  }

  @media only screen and (min-width: 1451px) {
    .service__name {
      padding: 31px 0px 0px 322px !important;
    }
    .outlets {
      padding: 108px 325px 0px 325px !important;
    }

    .room-services-menus {
      padding: 0px 325px 0px 325px !important;
    }
  }
</style>
